import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from './loadingButton';


export const ConfirmationDialog = ({
  open,
  onConfirm,
  onCancel,
  loading,
  title,
  message
}: {
  open: boolean,
  onConfirm: () => void,
  onCancel: () => void,
  loading: boolean,
  title: string
  message: string
}) => {
  const { t } = useTranslation(['layout']);
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby='confirm-dialog'
    >
      <DialogTitle id='dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} autoFocus>
          {t('CANCEL')}
        </Button>
        <LoadingButton onClick={onConfirm} disabled={loading} loading={loading} color='primary'>
          {t('OK')}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  )
}

import React from 'react';
import {
  Container,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  Paper,
  useMediaQuery,
  useTheme,
  StepContent,
  Grid,
  Button,
  makeStyles,
  Theme,
  createStyles
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../components/loadingButton';

export enum SingupWizardStep {
  SIGNUP, VERIFY_ACCOUNT, COMPLETE_PROFILE, KYC
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      paddingTop: theme.spacing(3)
    },
    stepper: {
      padding: 0,
      backgroundColor: 'inherit'
    }
  }),
);

export const SignupWizard = ({
  children,
  step,
  next,
  canGoNext = true,
  showButtons = true,
  loading = false
}: {
  children: any,
  step: SingupWizardStep,
  next: () => void,
  canGoNext: boolean,
  loading?: boolean,
  showButtons?: boolean
}) => {
  const { t } = useTranslation('signup');
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const getStepProps = (currentStep: SingupWizardStep) => {
    return {
      key: currentStep,
      completed: step > currentStep,
      active: currentStep === step
    }
  }
  const getStepContent = (currentStep: SingupWizardStep) => {
    if (isXs && currentStep === step) {
      return (
        <StepContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {children}
            </Grid>
            {getButtons()}
          </Grid>
        </StepContent>
      );
    }
    else if (isXs) return <StepContent />;
    else return null;
  }

  const getButtons = () => {
    if (!showButtons) return null;
    return (
      <Grid item xs={12}>
        <Grid container spacing={2} justify='flex-end'>
          <Grid item>
            <LoadingButton
              variant='contained'
              id={`step-${step}`}
              color='primary'
              disabled={!canGoNext || loading}
              loading={loading}
              onClick={next}>
              {t('SUBMIT')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const getStepper = () => (
    <Grid container direction='column'>
      <Grid item xs={12}>
        <Stepper
          orientation={isXs ? 'vertical' : 'horizontal'}
          className={classes.stepper}
          alternativeLabel={!isXs}>
          <Step {...getStepProps(SingupWizardStep.SIGNUP)}>
            <StepLabel>{t('SIGNUP')}</StepLabel>
            {getStepContent(SingupWizardStep.SIGNUP)}
          </Step>
          <Step {...getStepProps(SingupWizardStep.VERIFY_ACCOUNT)}>
            <StepLabel>{t('VERIFY_ACCOUNT')}</StepLabel>
            {getStepContent(SingupWizardStep.VERIFY_ACCOUNT)}
          </Step>
          <Step {...getStepProps(SingupWizardStep.COMPLETE_PROFILE)}>
            <StepLabel>{t('COMPLETE_PROFILE')}</StepLabel>
            {getStepContent(SingupWizardStep.COMPLETE_PROFILE)}
          </Step>
          <Step {...getStepProps(SingupWizardStep.KYC)}>
            <StepLabel>{t('KYC')}</StepLabel>
            {getStepContent(SingupWizardStep.KYC)}
          </Step>
        </Stepper>
      </Grid>
      {!isXs &&
        <Grid item xs={12}>
          <Box padding={2}>
            <Grid container direction='column' spacing={2}>
              <Grid item xs={12}>
                {children}
              </Grid>
              {getButtons()}
            </Grid>
          </Box>
        </Grid>
      }
    </Grid>
  )

  return (
    <Container>
      <Typography variant='h3' gutterBottom>
        {t('SIGNUP_TITLE')}
      </Typography>
      {isXs &&
        getStepper()
      }
      {!isXs &&
        <Paper className={classes.paper}>
          {getStepper()}
        </Paper>
      }
    </Container>
  );
}
import React from 'react';
import { Paper, Grid, Container, Box, Typography, TextField, makeStyles, Theme, createStyles, Link, FormControlLabel, Checkbox, CircularProgress, Button } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { useAuth, useContext, useApi } from '../context';
import utils from '../services/utils.service'
import { LoadingButton } from './loadingButton';
import { Country } from '../services/types/country.type';
import { Autocomplete } from '@material-ui/lab';
import { AlertDialog } from './alertDialog';
import { CircleFlag } from 'react-circle-flags';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { UserProfile } from '../services/types/userProfile.type';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    option: {
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
  })
);

type Credentials = {
  data: {
    prefix: string;
    phone: string;
    pin: string;
  };
  errors: {
    prefix: boolean;
    phone: boolean;
    pin: boolean;
  }
}

export const Login = ({ children, paper = true, onBack }: { children: any, paper?: boolean, onBack?: () => void }) => {
  const { t,i18n } = useTranslation(['login','countries']);
  const api = useApi();
  const context = useContext();
  const auth = useAuth();
  const history = useHistory();

  const classes = useStyles();

  const [loadingCountries, setLoadingCountries] = React.useState(false);
  const [loadingProfile, setLoadingProfile] = React.useState(false);
  const [loginError, setLoginError] = React.useState<string | undefined>(undefined);
  const [keepSignedIn, setKeepSignedIn] = React.useState(false);
  const [loggingIn, setLoggingIn] = React.useState(false);
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [profile, setProfile] = React.useState<UserProfile | null>(null);
  const [selectedCountry, selectCountry] = React.useState<Country | null>(null);
  const [credentials, setCredentials] = React.useReducer(
    (state: Credentials, action: any): Credentials => {
      if (!action.data) action.data = state.data;
      if (!action.errors) action.errors = state.errors
      return {
        data: {
          ...state.data,
          ...action.data
        },
        errors: {
          ...state.errors,
          ...action.errors
        }
      }
    },
    {
      data: { prefix: '', phone: '', pin: '' }, errors: { prefix: false, phone: false, pin: false }
    }
  );

  React.useEffect(() => {
    utils.runAsync(async () => {
      setLoadingCountries(true);
      setCountries(await api.getCountries());
    }, () => {
      setLoadingCountries(false);
    });
  }, []);

  React.useEffect(() => {
    if (context.data.auth.isSignedIn) {
      utils.runAsync(async () => {
        setLoadingProfile(true);
        setProfile(await api.getMyProfile());
      }, () => {
        setLoadingProfile(false);
      });
    }
  }, [context.data.auth.isSignedIn]);

  React.useEffect(() => {
    if (profile) {
      if (!profile.profile.complete) {
        history.push('/signup/profile');
      } else if (!profile.kyc.valid) {
        history.push('/signup/kyc');
      }
    }
  }, [profile]);

  React.useEffect(() => {
    let prefix = ''
    if (selectedCountry) {
      prefix = '+' + selectedCountry.prefix;
    }
    setCredentials({ data: { prefix }, errors: { prefix: false } });
  }, [selectedCountry]);

  const login = () => {
    const errors = {
      prefix: credentials.data.prefix.length === 0,
      phone: credentials.data.phone.length === 0,
      pin: credentials.data.pin.length === 0
    };
    if (Object.values(errors).includes(true)) {
      setCredentials({ errors });
      return;
    }
    setLoggingIn(true);
    utils.runAsync(async () => {
      const response = await auth.login(credentials.data.prefix + credentials.data.phone, credentials.data.pin, keepSignedIn);
      if (!response.success) {
        if (!response.valid_credentials) {
          setLoginError(t('WRONG_CREDENTIALS'));
        } else if (!response.account_verified) {
          history.push('/signup/verify');
        } else if (!response.valid_profile) {
          history.push('/signup/profile');
        } else if (!response.valid_kyc) {
          history.push('/signup/kyc');
        } else {
          setLoginError(t('GENERIC_LOGIN_ERROR'));
        }
      }
    }, () => {
      setLoggingIn(false);
    })
  }

  const toggleKeepSignedIn = () => {
    setKeepSignedIn(!keepSignedIn);
  }

  const handleFormUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({ data: { [e.target.name]: e.target.value }, errors: { [e.target.name]: false } });
  }

  if (loadingProfile || loadingCountries) {
    return (
      <Grid container justify='center'>
        <CircularProgress />
      </Grid>
    )
  }

  if (context.data.auth.isSignedIn) {
    return children;
  }
  const getLoginBox = () => (
    <>
      <AlertDialog
        open={loginError !== undefined}
        title={t('COULD_NOT_LOGIN')}
        message={loginError ? loginError : ''}
        onClose={() => setLoginError(undefined)}
      />
      <Box padding={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container justify='center'>
              <Grid item>
                <Trans t={t} i18nKey='PLEASE_LOGIN'>
                  <Typography variant='h5' align='center'>
                    Log in required
                  </Typography>
                </Trans>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify='center'>
              <Grid item>
                <Trans t={t} i18nKey='LOGIN_INSTRUCTIONS'>
                  <Typography variant='body1' align='justify'>
                    Use your credentials to sign in. If you don't already have an account head over to
                    <Link component={RouterLink} to='/signup'> Signup </Link>
                    for further instructions about registration.
                  </Typography>
                </Trans>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              onChange={(event, value) => {
                selectCountry(value);
              }}
              value={selectedCountry}
              options={countries}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => `+${option.prefix} - ${t('countries:'+option.iso3)}`}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option) => (
                <React.Fragment>
                  <CircleFlag countryCode={option.iso2.toLowerCase()} height={25} cdnUrl="/" />
                  <span style={{ marginLeft: 10 }}>{`+${option.prefix}`} - {t('countries:'+option.iso3)}</span>
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Prefix')}
                  variant='outlined'
                  error={credentials.errors.prefix}
                  helperText={credentials.errors.prefix ? t('REQUIRED') : ''}
                  fullWidth={true}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: selectedCountry ? (
                      <CircleFlag countryCode={selectedCountry.iso2.toLowerCase()} height={25} cdnUrl="/" />
                    ) : undefined
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'chrome-off',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              label={t('Phone number')}
              error={credentials.errors.phone}
              helperText={credentials.errors.phone ? t('REQUIRED') : ''}
              variant='outlined'
              name={'phone'}
              fullWidth={true}
              onChange={handleFormUpdate}
              inputProps={{
                autoComplete: 'chrome-off',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('PIN')}
              variant='outlined'
              fullWidth={true}
              error={credentials.errors.pin}
              helperText={credentials.errors.pin ? t('REQUIRED') : ''}
              onChange={handleFormUpdate}
              name={'pin'}
              type='password'
              inputProps={{
                autoComplete: 'chrome-off',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button size='small' color='inherit' onClick={() => {
              history.push('/recover-password')
            }} >
              {t('RECOVER_PASSWORD')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={keepSignedIn}
                  onChange={toggleKeepSignedIn}
                  color='primary'
                />
              }
              label={t('KEEP_ME_SIGNED_IN')}
            />
          </Grid>
          <Grid item xs={12}>
            <Trans t={t} i18nKey='TERMS+CONDITIONS'>
              <Typography variant='body2' align='center'>
                By loggin in you accept the <Link href={i18n.language==='es'?'https://www.zendy.tel/terminos-condiciones':'http://www.zendy.tel/en/terms-conditions'} target="_blank">terms & conditions</Link> of this service.
              </Typography>
            </Trans>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify='flex-end' spacing={2}>
              {onBack &&
              <Grid item>
                <Button size='large' onClick={() => onBack()}>
                  {t('BACK')}
                </Button>
              </Grid>
              }
              <Grid item>
                <LoadingButton variant='outlined' size='large' color='secondary' onClick={login} disabled={loggingIn} loading={loggingIn}>
                  {t('Login')}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );

  return (
    <Grid container justify='center' alignContent='center' alignItems='center'>
      <Grid item xs={12}>
        {paper &&
          <Paper>
            {getLoginBox()}
          </Paper>
        }
        {!paper &&
          getLoginBox()
        }
      </Grid>
    </Grid>
  )


}
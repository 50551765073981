import React, { Reducer } from 'react';
import moment from 'moment';
import { Grid, Typography, CircularProgress, TextField, makeStyles, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { useContext, useApi } from '../context';
import utils from '../services/utils.service';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Country } from '../services/types/country.type';
import { IdCardType } from '../services/types/idCardType.enum';
import { useHistory, Redirect } from 'react-router-dom';
import { TransferWizard, TransferWizardStep } from './wizard';
import { Login } from '../components/login';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@material-ui/pickers';
import { Moment } from 'moment';
import { ButtonGroup } from '@material-ui/core';
import { Contact } from '../services/types/contact.type';
import { Region } from '../services/types/region.type';
import { TransactionTypeCode } from '../services/types/transactionType.type';

const phoneRegexp = new RegExp('^\\+[0-9]{8,15}$');
const emailRegexp = new RegExp('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,6})+$');
const idRegexp = new RegExp('^[a-zA-Z0-9]{6,20}$');

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});



export enum BeneficiaryType {
  NEW, EXISTING, MYSELF
}

type Form = {
  name: string;
  lastName: string;
  address: string;
  city: string;
  region: string;
  postalCode: string;
  countryOfResidence: Country | null;
  idCardType: IdCardType | null;
  idCardIssueCountry: Country | null;
  idCardNumber: string;
  idCardIssueDate: Moment | null;
  idCardExpiryDate: Moment | null;
  phone: string;
  email: string;
  concept: string;
}
type FormErrors = {
  name: string | undefined;
  lastName: string | undefined;
  address: string | undefined;
  city: string | undefined;
  region: string | undefined;
  postalCode: string | undefined;
  countryOfResidence: string | undefined;
  idCardType: string | undefined;
  idCardIssueCountry: string | undefined;
  idCardNumber: string | undefined;
  idCardIssueDate: string | undefined;
  idCardExpiryDate: string | undefined;
  phone: string | undefined;
  email: string | undefined;
  concept: string | undefined;
}

type FormWithErrors = {
  form: Form;
  errors: FormErrors;
}

export const Beneficiary = () => {
  const api = useApi();
  const context = useContext();
  const history = useHistory();

  const emptyFormErrors = {
    name: undefined,
    lastName: undefined,
    address: undefined,
    city: undefined,
    region: undefined,
    postalCode: undefined,
    countryOfResidence: undefined,
    idCardType: undefined,
    idCardIssueCountry: undefined,
    idCardNumber: undefined,
    idCardIssueDate: undefined,
    idCardExpiryDate: undefined,
    phone: undefined,
    email: undefined,
    concept: undefined
  };

  const emptyForm = {
    name: '',
    lastName: '',
    address: '',
    city: '',
    region: '',
    postalCode: '',
    countryOfResidence: null,
    idCardType: null,
    idCardIssueCountry: null,
    idCardNumber: '',
    idCardIssueDate: null,
    idCardExpiryDate: null,
    phone: '',
    email: '',
    concept: ''
  }

  const { t } = useTranslation(['beneficiary', 'documentTypes', 'countries']);

  const [loading, setLoading] = React.useState(true);
  const [loadingRegions, setLoadingRegions] = React.useState(false);
  const [savingContact, setSavingContact] = React.useState(false);
  const [beneficiaryType, setBeneficiaryType] = React.useState(BeneficiaryType.NEW);
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [shouldSaveContact, setShouldSaveContact] = React.useState<boolean | undefined>(undefined);
  const [showSaveContactDialog, setShowSaveContactDialog] = React.useState(false);
  const [contacts, setContacts] = React.useState<Contact[]>([]);
  const [selectedContact, selectContact] = React.useState<Contact | null>(null);
  const [regions, setRegions] = React.useState<Region[]>([]);
  const [selectedRegion, selectRegion] = React.useState<Region | null>(null);

  const [formWithErrors, setFormWithErrors] = React.useReducer((state: FormWithErrors, action: { form?: Partial<Form>, errors?: Partial<FormErrors> }) => {
    const newFormWithErrors: FormWithErrors = { ...state };
    if (action.form) {
      newFormWithErrors.form = {
        ...state.form,
        ...action.form
      }
    }
    if (action.errors) {
      newFormWithErrors.errors = {
        ...state.errors,
        ...action.errors
      }
    }
    return newFormWithErrors;
  }, { form: emptyForm, errors: emptyFormErrors });

  React.useEffect(() => {
    setLoading(true);
    utils.runAsync(async () => {
      setCountries(await api.getCountries());
      if (context.data.beneficiaryType) {
        setBeneficiaryType(context.data.beneficiaryType);
      }
    }, () => {
      setLoading(false);
    });
  }, []);

  React.useEffect(() => {
    setShouldSaveContact(undefined);
    selectContact(null);
    setFormWithErrors({ form: { ...emptyForm, concept: formWithErrors.form.concept }, errors: emptyFormErrors });
    const concept = formWithErrors.form.concept ? formWithErrors.form.concept : context.data.concept ? context.data.concept : '';
    if (beneficiaryType === BeneficiaryType.MYSELF) {
      utils.runAsync(async () => {
        setLoading(true);
        const countries = await api.getCountries();
        const profile = await api.getMyProfile();
        const idCardIssueCountry = countries.find(c => c.id === profile.kyc.data.id_card_country_id);
        const residenceCountry = countries.find(c => c.id === profile.profile.data.residence_country_id);
        setFormWithErrors({
          form: {
            name: profile.profile.data.first_name,
            lastName: profile.profile.data.last_name,
            address: profile.profile.data.address ? profile.profile.data.address : '',
            city: profile.profile.data.city ? profile.profile.data.city : '',
            region: profile.profile.data.region ? profile.profile.data.region : '',
            postalCode: profile.profile.data.postal_code ? profile.profile.data.postal_code : '',
            countryOfResidence: residenceCountry ? residenceCountry : null,
            idCardType: profile.kyc.data.id_card_type === 'PASSPORT' ? IdCardType.PASSPORT : IdCardType.NATIONAL_ID_CARD,
            idCardIssueCountry: idCardIssueCountry ? idCardIssueCountry : null,
            idCardNumber: profile.kyc.data.id_card_number,
            idCardIssueDate: profile.kyc.data.id_card_issue_date ? moment(profile.kyc.data.id_card_issue_date) : null,
            idCardExpiryDate: profile.kyc.data.id_card_expiry_date ? moment(profile.kyc.data.id_card_expiry_date) : null,
            phone: profile.profile.data.phone,
            email: profile.profile.data.email,
            concept
          },
          errors: emptyFormErrors
        });
      }, () => {
        setLoading(false);
      });
    } else if (beneficiaryType === BeneficiaryType.EXISTING) {
      utils.runAsync(async () => {
        setLoading(true);
        const contacts = await api.getContacts();
        setContacts(contacts);
        if (context.data.beneficiaryContactId) {
          const contact = contacts.find(c => c.id === context.data.beneficiaryContactId);
          selectContact(contact ? contact : null);
        }
      }, () => {
        setLoading(false);
      });
    } else {
      if (context.data.beneficiaryType === BeneficiaryType.NEW && context.data.beneficiary) {
        setFormWithErrors({
          form: {
            name: context.data.beneficiary.name,
            lastName: context.data.beneficiary.lastName,
            idCardIssueCountry: context.data.beneficiary.idCardIssueCountry,
            idCardType: context.data.beneficiary.idCardType,
            idCardNumber: context.data.beneficiary.idCardNumber,
            idCardIssueDate: context.data.beneficiary.idCardIssueDate ? moment(context.data.beneficiary.idCardIssueDate) : null,
            idCardExpiryDate: context.data.beneficiary.idCardExpiryDate ? moment(context.data.beneficiary.idCardExpiryDate) : null,
            countryOfResidence: context.data.beneficiary.countryOfResidence,
            region: context.data.beneficiary.region,
            address: context.data.beneficiary.address,
            postalCode: context.data.beneficiary.postalCode ? context.data.beneficiary.postalCode : '',
            city: context.data.beneficiary.city,
            phone: context.data.beneficiary.phone,
            email: context.data.beneficiary.email,
            concept
          }
        });
      }
    }
  }, [beneficiaryType]);

  React.useEffect(() => {
    if (shouldSaveContact !== undefined) {
      setShowSaveContactDialog(false);
      next();
    }
  }, [shouldSaveContact])

  React.useEffect(() => {
    if (selectedContact === null) return;
    const idCardIssueCountry = countries.find(c => c.id === selectedContact.id_card_country_id);
    const residenceCountry = countries.find(c => c.id === selectedContact.residence_country_id);
    setFormWithErrors({
      form: {
        name: selectedContact.first_name,
        lastName: selectedContact.last_name,
        address: selectedContact.address ? selectedContact.address : '',
        city: selectedContact.city ? selectedContact.city : '',
        region: selectedContact.region ? selectedContact.region : '',
        postalCode: selectedContact.postal_code ? selectedContact.postal_code : '',
        countryOfResidence: residenceCountry ? residenceCountry : null,
        idCardType: selectedContact.id_card_type,
        idCardIssueCountry: idCardIssueCountry ? idCardIssueCountry : null,
        idCardNumber: selectedContact.id_card_number,
        idCardIssueDate: selectedContact.id_card_issue_date ? moment(selectedContact.id_card_issue_date) : null,
        idCardExpiryDate: selectedContact.id_card_expiry_date ? moment(selectedContact.id_card_expiry_date) : null,
        phone: selectedContact.phone,
        email: selectedContact.email,
        concept: formWithErrors.form.concept.length > 0 ? formWithErrors.form.concept : context.data.concept ? context.data.concept : ''
      },
      errors: emptyFormErrors
    });
    utils.runAsync(async () => {
      setLoadingRegions(true);
      const regions = await api.getRegions(residenceCountry!.id);
      setRegions(regions);
      const foundRegion = regions.find(r => r.code === selectedContact.region);
      if (foundRegion) {
        selectRegion(foundRegion);
      }
    }, () => {
      setLoadingRegions(false);
    });
  }, [selectedContact]);


  const loadRegions = (id: number) => {
    utils.runAsync(async () => {
      setLoadingRegions(true);
      setRegions(await api.getRegions(id));
    }, () => {
      setLoadingRegions(false);
    });
  }

  const validate = () => {
    const errors = Object.assign({}, emptyFormErrors);
    if (form.name.length === 0) {
      errors.name = t('REQUIRED');
    }
    if (form.lastName.length === 0) {
      errors.lastName = t('REQUIRED');
    }
    if (form.idCardIssueCountry === null) {
      errors.idCardIssueCountry = t('REQUIRED');
    }
    if (form.idCardType === null) {
      errors.idCardType = t('REQUIRED');
    }
    if (form.idCardNumber.length === 0) {
      errors.idCardNumber = t('REQUIRED');
    } else if (!idRegexp.test(form.idCardNumber)) {
      errors.idCardNumber = t('WRONG_IDCARD_FORMAT');
    }
    if (form.countryOfResidence === null) {
      errors.countryOfResidence = t('REQUIRED');
    }
    if (form.region.length === 0) {
      errors.region = t('REQUIRED');
    }
    if (form.address.length === 0) {
      errors.address = t('REQUIRED');
    }
    // if (form.postalCode.length === 0) {
    //   errors.postalCode = t('REQUIRED');
    // }
    if (form.city.length === 0) {
      errors.city = t('REQUIRED');
    } else if (form.city.length > 40) {
      errors.city = t('TOO_LONG');
    }
    if (form.phone.length === 0) {
      errors.phone = t('REQUIRED');
    } else if (!phoneRegexp.test(form.phone)) {
      errors.phone = t('INVALID_PHONE');
    }
    if (form.email.length === 0) {
      errors.email = t('REQUIRED');
    } else if (!emailRegexp.test(form.email)) {
      errors.email = t('INVALID_EMAIL');
    }
    if (form.concept.length === 0) {
      errors.concept = t('REQUIRED');
    }
    if (form.concept.length > 40) {
      errors.concept = t('TOO_LONG');
    }
    const isValid = Object.keys(errors).find(k => (errors as any)[k] !== undefined) === undefined;
    if (!isValid) {
      setFormWithErrors({ errors });
    }
    return isValid;
  }

  const next = () => {
    if (!validate()) {
      return false;
    }
    utils.runAsync(async () => {
      let actualBeneficiaryType = beneficiaryType;
      let contactId = selectedContact?.id;
      if (beneficiaryType === BeneficiaryType.NEW) {
        if (shouldSaveContact === undefined) {
          setShowSaveContactDialog(true);
          return;
        } else if (shouldSaveContact) {
          setSavingContact(true);
          contactId = await api.addContact({
            first_name: form.name,
            last_name: form.lastName,
            id_card_country_id: form.idCardIssueCountry!.id,
            id_card_type: form.idCardType!,
            id_card_number: form.idCardNumber,
            id_card_issue_date: form.idCardIssueDate?.format('YYYY-MM-DD'),
            id_card_expiry_date: form.idCardExpiryDate?.format('YYYY-MM-DD'),
            residence_country_id: form.countryOfResidence!.id,
            region: form.region,
            city: form.city,
            postal_code: form.postalCode ? form.postalCode : undefined,
            address: form.address,
            phone: form.phone,
            email: form.email
          });
          actualBeneficiaryType = BeneficiaryType.EXISTING;
        }
      }
      context.setData({
        beneficiary: {
          name: form.name,
          lastName: form.lastName,
          idCardIssueCountry: form.idCardIssueCountry!,
          idCardType: form.idCardType!,
          idCardNumber: form.idCardNumber,
          idCardIssueDate: form.idCardIssueDate?.format('YYYY-MM-DD'),
          idCardExpiryDate: form.idCardExpiryDate?.format('YYYY-MM-DD'),
          countryOfResidence: form.countryOfResidence!,
          region: form.region,
          city: form.city,
          postalCode: form.postalCode ? form.postalCode : undefined,
          address: form.address,
          phone: form.phone,
          email: form.email
        },
        beneficiaryType: actualBeneficiaryType,
        beneficiaryContactId: contactId,
        concept: form.concept
      });
      history.push('/transfer/checkout');
    }, () => {
      setSavingContact(false);
    });
  }

  const classes = useStyles();

  if (!context.data.quotation) {
    return (<Redirect to='/' />);
  }

  const shouldShowBeneficiaryForm = () => {
    if (beneficiaryType === BeneficiaryType.EXISTING) {
      return !loading && selectedContact !== null;
    }
    return !loading;
  }

  const form = formWithErrors.form;
  const formErrors = formWithErrors.errors;

  let idCardOptions = [IdCardType.PASSPORT, IdCardType.NATIONAL_ID_CARD];
  if (
    (context.data.productSelectionParams?.transactionType.code === TransactionTypeCode.BANK_TRANSFER &&
      context.data.productSelectionParams?.country.iso2 === 'BR') ||
    (context.data.productSelectionParams?.country.iso2 === 'VE')
  ) {
    idCardOptions = [IdCardType.NATIONAL_ID_CARD];
  }

  return (
    <TransferWizard
      step={TransferWizardStep.BENEFICIARY}
      loading={savingContact}
      back={() => history.push('/transfer/options')}
      canGoNext={shouldShowBeneficiaryForm()}
      next={next}
      showButtons={context.data.auth.isSignedIn}>
      <Dialog
        open={showSaveContactDialog}
        onClose={() => setShowSaveContactDialog(false)}
        aria-labelledby='save-contact-dialog'
      >
        <DialogTitle id='dialog-title'>{t('SAVE_CONTACT_TITLE')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('SAVE_CONTACT_MESSAGE')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setShouldSaveContact(false);
          }}>
            {t('NO')}
          </Button>
          <Button onClick={() => {
            setShouldSaveContact(true);
          }} color='primary' autoFocus>
            {t('YES')}
          </Button>
        </DialogActions>
      </Dialog >
      <Login paper={false} onBack={() => history.push('/transfer/options')}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              {t('BENEFICIARY_INTRO')}
            </Typography>
          </Grid>
          <Grid item xs={12} container justify='center'>
            <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
              <Button
                disabled={loading}
                variant={beneficiaryType === BeneficiaryType.NEW ? 'contained' : 'outlined'}
                onClick={() => setBeneficiaryType(BeneficiaryType.NEW)}>
                {t('NEW_BENEFICIARY')}
              </Button>
              <Button
                disabled={loading}
                variant={beneficiaryType === BeneficiaryType.EXISTING ? 'contained' : 'outlined'}
                onClick={() => setBeneficiaryType(BeneficiaryType.EXISTING)}>
                {t('EXISTING_BENEFICIARY')}
              </Button>
              <Button
                disabled={loading || !context.data.quotation.allows_self_remittance}
                variant={beneficiaryType === BeneficiaryType.MYSELF ? 'contained' : 'outlined'}
                onClick={() => setBeneficiaryType(BeneficiaryType.MYSELF)}>
                {t('TO_MYSELF')}
              </Button>
            </ButtonGroup>
          </Grid>
          {!loading && beneficiaryType === BeneficiaryType.EXISTING &&
            <Grid item xs={12}>
              <Autocomplete
                onChange={(event, value) => {
                  selectContact(value);
                }}
                getOptionSelected={(option, value) => option.id === value.id}
                value={selectedContact}
                noOptionsText={t('NO_OPTIONS')}
                options={contacts}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => `${option.first_name} ${option.last_name} - ${option.email}`}
                renderOption={(option) => `${option.first_name} ${option.last_name} - ${option.email}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('CONTACT')}
                    variant='outlined'
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'chrome-off',
                    }}
                  />
                )}
              />
            </Grid>

          }
          {shouldShowBeneficiaryForm() &&
            <>
              <Grid item xs={12}>
                <Typography variant='h6'>
                  {t('ID')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth={true}
                  label={t('NAME')}
                  error={formErrors.name !== undefined}
                  helperText={formErrors.name}
                  variant='outlined'
                  value={form.name}
                  onChange={(event) => {
                    setFormWithErrors({ form: { name: event.target.value }, errors: { name: undefined } });
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth={true}
                  label={t('LAST_NAME')}
                  variant='outlined'
                  value={form.lastName}
                  error={formErrors.lastName !== undefined}
                  helperText={formErrors.lastName}
                  onChange={(event) => {
                    setFormWithErrors({ form: { lastName: event.target.value }, errors: { lastName: undefined } });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  onChange={(event, value) => {
                    setFormWithErrors({ form: { idCardIssueCountry: value }, errors: { idCardIssueCountry: undefined } });
                  }}
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={form.idCardIssueCountry}
                  options={countries}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => t('countries:' + option.iso3)}
                  renderOption={(option) => t('countries:' + option.iso3)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={formErrors.idCardIssueCountry !== undefined}
                      helperText={formErrors.idCardIssueCountry}
                      label={t('ID_ISSUE_COUNTRY')}
                      variant='outlined'
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'chrome-off',
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  onChange={(event, value) => {
                    setFormWithErrors({ form: { idCardType: value }, errors: { idCardType: undefined } });
                  }}
                  getOptionSelected={(option, value) => option === value}
                  value={form.idCardType}
                  options={idCardOptions}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => t(`documentTypes:${option}`)}
                  renderOption={(option) => t(`documentTypes:${option}`)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={formErrors.idCardType !== undefined}
                      helperText={formErrors.idCardType}
                      label={t('ID_CARD_TYPE')}
                      variant='outlined'
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'chrome-off',
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  clearable
                  okLabel={t('OK')}
                  cancelLabel={t('CANCEL')}
                  clearLabel={t('CLEAR')}
                  fullWidth={true}
                  label={t('ID_CARD_EXPIRY_DATE')}
                  format='DD/MM/YYYY'
                  disablePast
                  inputVariant='outlined'
                  value={form.idCardExpiryDate}
                  error={formErrors.idCardExpiryDate !== undefined}
                  helperText={formErrors.idCardExpiryDate ? formErrors.idCardExpiryDate : t('EXPIRY_DATE_NOT_MANDATORY')}
                  onChange={(value) => {
                    setFormWithErrors({ form: { idCardExpiryDate: value }, errors: { idCardExpiryDate: undefined } });
                  }}
                />
              </Grid>
              <Grid item xs={12} >
                <TextField
                  fullWidth={true}
                  label={t('ID_CARD_NUMBER')}
                  variant='outlined'
                  value={form.idCardNumber}
                  error={formErrors.idCardNumber !== undefined}
                  helperText={formErrors.idCardNumber}
                  onChange={(event) => {
                    setFormWithErrors({ form: { idCardNumber: event.target.value }, errors: { idCardNumber: undefined } });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>
                  {t('ADDRESS')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  onChange={(event, value) => {
                    let phone = formWithErrors.form.phone;
                    if (phone.length < 5 && value) {
                      phone = `+${value.prefix}`;
                    }
                    setFormWithErrors({ form: { countryOfResidence: value, phone, region: '' }, errors: { countryOfResidence: undefined } });
                    selectRegion(null);
                    if (value) {
                      loadRegions(value.id);
                    }
                  }}
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={form.countryOfResidence}
                  options={countries}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => t('countries:' + option.iso3)}
                  renderOption={(option) => t('countries:' + option.iso3)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('COUNTRY_OF_RESIDENCE')}
                      variant='outlined'
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'chrome-off',
                      }}
                      error={formErrors.countryOfResidence !== undefined}
                      helperText={formErrors.countryOfResidence}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                {regions.length === 0 &&
                  <TextField
                    fullWidth={true}
                    label={t('STATE_OR_PROVINCE')}
                    variant='outlined'
                    value={form.region}
                    error={formErrors.region !== undefined}
                    helperText={formErrors.region}
                    onChange={(event) => {
                      setFormWithErrors({ form: { region: event.target.value }, errors: { region: undefined } });
                    }}
                  />
                }
                {regions.length > 0 &&
                  <Autocomplete
                    disabled={loadingRegions}
                    value={selectedRegion}
                    onChange={(event, value) => {
                      selectRegion(value);
                      if (value === null) {
                        setFormWithErrors({ form: { region: '' }, errors: { region: undefined } });
                      } else {
                        setFormWithErrors({ form: { region: value.code }, errors: { region: undefined } });
                      }
                    }}
                    getOptionSelected={(option, value) => option.code === value.code}
                    options={regions}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    renderOption={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('STATE_OR_PROVINCE')}
                        variant='outlined'
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'chrome-off',
                        }}
                        error={formErrors.region !== undefined}
                        helperText={formErrors.region}
                      />
                    )}
                  />
                }
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth={true}
                  label={t('ADDRESS')}
                  variant='outlined'
                  value={form.address}
                  error={formErrors.address !== undefined}
                  helperText={formErrors.address}
                  onChange={(event) => {
                    setFormWithErrors({ form: { address: event.target.value }, errors: { address: undefined } });
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth={true}
                  label={t('POSTAL_CODE')}
                  variant='outlined'
                  value={form.postalCode}
                  error={formErrors.postalCode !== undefined}
                  helperText={formErrors.postalCode ? formErrors.postalCode : t('POSTAL_CODE_NOT_MANDATORY')}
                  onChange={(event) => {
                    setFormWithErrors({ form: { postalCode: event.target.value }, errors: { postalCode: undefined } });
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth={true}
                  label={t('CITY')}
                  variant='outlined'
                  value={form.city}
                  error={formErrors.city !== undefined}
                  helperText={formErrors.city}
                  onChange={(event) => {
                    setFormWithErrors({ form: { city: event.target.value }, errors: { city: undefined } });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>
                  {t('CONTACT_DATA')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth={true}
                  label={t('PHONE')}
                  variant='outlined'
                  value={form.phone}
                  error={formErrors.phone !== undefined}
                  helperText={formErrors.phone}
                  onChange={(event) => {
                    setFormWithErrors({ form: { phone: event.target.value }, errors: { phone: undefined } });
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth={true}
                  label={t('EMAIL')}
                  variant='outlined'
                  value={form.email}
                  error={formErrors.email !== undefined}
                  helperText={formErrors.email}
                  onChange={(event) => {
                    setFormWithErrors({ form: { email: event.target.value }, errors: { email: undefined } });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>
                  {t('TRANSACTION_DETAILS')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth={true}
                  label={t('CONCEPT')}
                  variant='outlined'
                  value={form.concept}
                  error={formErrors.concept !== undefined}
                  helperText={formErrors.concept}
                  onChange={(event) => {
                    setFormWithErrors({ form: { concept: event.target.value }, errors: { concept: undefined } });
                  }}
                />
              </Grid>
            </>
          }
          {loading &&
            <Grid item xs={12}>
              <Grid container justify='center'>
                <CircularProgress />
              </Grid>
            </Grid>
          }
        </Grid>
      </Login>
    </TransferWizard>
  );
}
import React from 'react';
import { Container, Typography, Paper, Grid, MenuItem, MenuList, withStyles, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Login } from '../components/login';
import { useAuth } from '../context';
import { Transactions } from './transactions';
import { Beneficiaries } from './beneficiaries';
import { Profile } from './profile';

enum SelectableMenuItem {
  TRANSACTIONS, BENEFICIARIES, PROFILE
}

const Component = () => {
  const { t } = useTranslation('account');
  const auth = useAuth();
  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&$selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        }
      },
    },
    selected: {},
  }))(MenuItem);

  const [selectedItem, selectItem] = React.useState<SelectableMenuItem>(SelectableMenuItem.TRANSACTIONS);

  const getContent = () => {
    if (selectedItem === SelectableMenuItem.TRANSACTIONS) {
      return <Transactions />
    } else if(selectedItem === SelectableMenuItem.BENEFICIARIES){
      return <Beneficiaries />
    }else{
      return <Profile/>
    }
  }

  return (

    <Grid container spacing={2}>
      <Grid item xs={12} md={3} sm={4}>
        <Paper>
          <MenuList>
            <StyledMenuItem
              onClick={() => selectItem(SelectableMenuItem.TRANSACTIONS)}
              selected={selectedItem === SelectableMenuItem.TRANSACTIONS} >
              {t('TRANSACTIONS')}
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => selectItem(SelectableMenuItem.BENEFICIARIES)}
              selected={selectedItem === SelectableMenuItem.BENEFICIARIES}>
              {t('BENEFICIARIES')}
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => selectItem(SelectableMenuItem.PROFILE)}
              selected={selectedItem === SelectableMenuItem.PROFILE} >
              {t('PROFILE')}
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => auth.logout()}>
              {t('LOGOUT')}
            </StyledMenuItem>
          </MenuList>
        </Paper>
      </Grid>
      <Grid item xs={12} md={9} sm={8} style={{ width: 0 }}>
        {getContent()}
      </Grid>
    </Grid>
  )
}

export const Account = () => {
  const { t } = useTranslation('account');
  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h3' gutterBottom>
            {t('ACCOUNT')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Login>
            <Component />
          </Login>
        </Grid>
      </Grid>
    </Container>
  );
}
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography, List, ListItem, ListItemIcon, ListItemAvatar, ListItemText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@material-ui/icons/Check';

export const TransferDisclaimer = ({
}: {
    }) => {
    const { t } = useTranslation('transferDisclaimer');
    const [open, setOpen] = React.useState(true);
    return (
        <Dialog
            open={open}
            maxWidth={'sm'}
            fullWidth
            onClose={() => {
                setOpen(false);
            }}
            aria-labelledby='alert-dialog'
        >
            <DialogTitle id='dialog-title'>{t('TRANSFER_DISCLAIMER_TITLE')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography>
                        {t('DONT_SEND_MONEY_TO_TITLE')}
                        <ul>
                            <li>{t('DONT_SEND_MONEY_TO_1')}</li>
                            <li>{t('DONT_SEND_MONEY_TO_2')}</li>
                            <li>{t('DONT_SEND_MONEY_TO_3')}</li>
                            <li>{t('DONT_SEND_MONEY_TO_4')}</li>
                            <li>{t('DONT_SEND_MONEY_TO_5')}</li>
                            <li>{t('DONT_SEND_MONEY_TO_6')}</li>
                            <li>{t('DONT_SEND_MONEY_TO_7')}</li>
                            <li>{t('DONT_SEND_MONEY_TO_8')}</li>
                            <li>{t('DONT_SEND_MONEY_TO_9')}</li>
                            <li>{t('DONT_SEND_MONEY_TO_10')}</li>
                        </ul>
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <CheckIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('TIP_1')} />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckIcon />
                            </ListItemIcon>
                            <ListItemText primary={<>
                                {t('TIP_2')} <a href='mailto:antifraude@skylink.com.es'>antifraude@skylink.com.es</a>.
                            </>} />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('TIP_3')} />
                        </ListItem>
                    </List>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setOpen(false);

                }} color='primary' autoFocus>
                    {t('ACCEPT')}
                </Button>
            </DialogActions>
        </Dialog >
    )
}

import { Container, createStyles, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { NewPassword } from './newPassword';
import { Username } from './username';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(3)
    },
  }),
);

export const RecoverPassword = () => {
  const { t } = useTranslation('recoverPassword');
  const classes = useStyles();
  const history = useHistory();
  const [username, setUsername] = React.useState<string | undefined>(undefined);

  const getStep = () => {
    if (username !== undefined) {
        return (
          <NewPassword username={username} onSuccess={() => {
            history.push('/account');
          }} />
        );
    }
    return (
      <Username onSuccess={(username) => {
        setUsername(username);
      }} />
    );
  }

  return (
    <Container>
      <Typography variant='h3' gutterBottom>
        {t('RECOVER_PASSWORD')}
      </Typography>
      <Paper className={classes.paper}>
        {getStep()}
      </Paper>
    </Container>
  )


}
import { Context } from '../context';
import { Config } from '../config';
import jwt from 'jsonwebtoken';
import moment from 'moment';

export class Auth {

  private accessToken?: string;

  constructor(
    private readonly config: Config,
    private readonly context: Context,
  ) { }

  private setAccessToken(accessToken: string, keepSignedIn: boolean) {
    this.accessToken = accessToken;
    if (keepSignedIn) {
      localStorage.setItem('accessToken', accessToken);
    } else {
      sessionStorage.setItem('accessToken', accessToken);
    }
  }

  public getAccessToken(): string {
    let accessToken: string | undefined;
    if (this.accessToken) {
      accessToken = this.accessToken;
    } else {
      if (sessionStorage.getItem('accessToken')) {
        accessToken = sessionStorage.getItem('accessToken')!;
      } else if (localStorage.getItem('accessToken')) {
        accessToken = localStorage.getItem('accessToken')!;
      }
    }
    if (accessToken && this.isAccessTokenValid(accessToken)) {
      return accessToken;
    } else if (accessToken) {
      this.logout();
    }
    throw new Error('No valid access token');
  }

  public attemptNonInteractiveLogin() {
    let accessToken: string | undefined;
    if (sessionStorage.getItem('accessToken')) {
      accessToken = sessionStorage.getItem('accessToken')!;
    } else if (localStorage.getItem('accessToken')) {
      accessToken = localStorage.getItem('accessToken')!;
    }
    if (accessToken && this.isAccessTokenValid(accessToken)) {
      this.accessToken = accessToken;
      this.context.setData({ auth: { ready: true, isSignedIn: true } });
    } else {
      this.context.setData({ auth: { ready: true, isSignedIn: false } });
    }
  }

  async login(
    username: string,
    password: string,
    keepSignedIn: boolean
  ): Promise<{
    success: boolean;
    account_verified: boolean;
    valid_kyc: boolean;
    valid_profile: boolean;
    valid_credentials: boolean;
  }> {
    const response = await fetch(
      this.config.API_BASE_URL + '/login',
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'x-tenant-key': this.config.TENANT_KEY
        },
        body: JSON.stringify({ username, password })
      }
    );
    if (response.ok) {
      const jsonResponse = await response.json();
      const token = jsonResponse.token;
      this.setAccessToken(token, keepSignedIn);
      if (jsonResponse.valid_kyc && jsonResponse.valid_profile) {
        this.context.setData({ auth: { ready: true, isSignedIn: true } });
        return {
          success: true,
          account_verified: true,
          valid_kyc: true,
          valid_profile: true,
          valid_credentials: true
        };
      } else {
        this.context.setData({ auth: { ready: true, isSignedIn: true } });
        return {
          success: false,
          account_verified: true,
          valid_kyc: jsonResponse.valid_kyc,
          valid_profile: jsonResponse.valid_profile,
          valid_credentials: true
        };
      }
    } else if (response.status === 401) {
      this.context.setData({ auth: { ready: true, isSignedIn: false } });
      return {
        success: false,
        account_verified: false,
        valid_kyc: false,
        valid_profile: false,
        valid_credentials: false
      };
    } else if (response.status === 409) {
      this.context.setData({ auth: { ready: true, isSignedIn: false, tempCredentials: { username, password } } });
      return {
        success: false,
        account_verified: false,
        valid_kyc: false,
        valid_profile: false,
        valid_credentials: true
      };
    } else {
      throw new Error('Could not login.')
    }
  }

  logout() {
    this.accessToken = undefined;
    localStorage.removeItem('accessToken');
    sessionStorage.removeItem('accessToken');
    this.context.setData({ auth: { ready: true, isSignedIn: false, tempCredentials: undefined } });
  }

  private isAccessTokenValid(accessToken: string): boolean {
    try {
      const exp = jwt.decode(accessToken).exp;
      return moment(exp * 1000).isAfter(moment());
    } catch (e) {
      return false;
    }
  }

}
import React, { useCallback } from 'react';
import { Grid, Typography, CircularProgress, TextField, FormControlLabel, Checkbox, makeStyles, InputAdornment, IconButton } from '@material-ui/core';
import { SignupWizard, SingupWizardStep } from './wizard';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Country } from '../services/types/country.type';
import utils from '../services/utils.service';
import { useApi, useContext } from '../context';
import { Autocomplete } from '@material-ui/lab';
import { CircleFlag } from 'react-circle-flags';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { AlertDialog } from '../components/alertDialog';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const emailRegexp = new RegExp('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,6})+$');
const phoneRegexp = new RegExp('^[0-9]{6,20}$');
const pinRegexp = new RegExp('^[0-9]{4}$');

const useStlyes = makeStyles((theme) => ({
  option: {
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
}))

type FormField = {
  value: string;
  errorState: boolean;
  helperText: string;
}

export const Signup = () => {
  const { t, i18n } = useTranslation(['signup', 'countries']);
  const history = useHistory();
  const api = useApi();
  const context = useContext();

  const [form, updateForm] = React.useReducer((
    state: { [key: string]: FormField },
    action: { field: string, data: Partial<FormField> }
  ): { [key: string]: FormField } => {
    return {
      ...state,
      [action.field]: {
        ...state[action.field],
        ...action.data
      }
    };
  },
    {
      name: {
        value: '',
        errorState: false,
        helperText: ''
      },
      lastName: {
        value: '',
        errorState: false,
        helperText: ''
      },
      email: {
        value: '',
        errorState: false,
        helperText: ''
      },
      prefix: {
        value: '',
        errorState: false,
        helperText: ''
      },
      phone: {
        value: '',
        errorState: false,
        helperText: ''
      },
      pin: {
        value: '',
        errorState: false,
        helperText: ''
      }
    }
  );

  const [conditionsAccepted, setConditionsAccepted] = React.useState(false);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [loadingCountries, setLoadingCountries] = React.useState(true);
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [selectedCountry, selectCountry] = React.useState<Country | null>(null);

  const [submitting, setSubmitting] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState({ body: '', title: '' });

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateForm({ field: e.target.name, data: { value: e.target.value, errorState: false, helperText: '' } });
  }

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      throw new Error('Recaptcha not yet available.')
    }
    const token = await executeRecaptcha('signup');
    if(!token){
      throw new Error('Could not get token.');
    }
    return token;
  }, [executeRecaptcha]);

  const classes = useStlyes();

  React.useEffect(() => {
    utils.runAsync(async () => {
      setCountries(await api.getCountries());
    }, () => {
      setLoadingCountries(false);
    });
  }, []);

  React.useEffect(() => {
    if (context.data.auth.isSignedIn) {
      history.push('/signup/kyc');
    }
  }, [context.data.auth]);

  React.useEffect(() => {
    if (selectedCountry) {
      updateForm({ field: 'prefix', data: { value: selectedCountry.prefix, errorState: false, helperText: '' } });
    } else {
      updateForm({ field: 'prefix', data: { value: '', errorState: false, helperText: '' } });
    }
  }, [selectedCountry]);

  const validate = () => {
    let isValid = true;
    if (form.name.value.length === 0) {
      isValid = false;
      updateForm({ field: 'name', data: { errorState: true, helperText: t('REQUIRED') } });
    }
    if (form.lastName.value.length === 0) {
      isValid = false;
      updateForm({ field: 'lastName', data: { errorState: true, helperText: t('REQUIRED') } });
    }
    if (!emailRegexp.test(form.email.value)) {
      isValid = false;
      updateForm({ field: 'email', data: { errorState: true, helperText: t('EMAIL_FORMAT_ERROR') } });
    }
    if (!phoneRegexp.test(form.phone.value)) {
      isValid = false;
      updateForm({ field: 'phone', data: { errorState: true, helperText: t('PHONE_FORMAT_ERROR') } });
    }
    if (!pinRegexp.test(form.pin.value)) {
      isValid = false;
      updateForm({ field: 'pin', data: { errorState: true, helperText: t('PIN_FORMAT_ERROR') } });
    }
    if (!selectedCountry) {
      isValid = false;
      updateForm({ field: 'prefix', data: { errorState: true, helperText: t('REQUIRED') } });
    }
    return isValid;
  }
  const submit = () => {

    if (!validate()) {
      return;
    }

    utils.runAsync(async () => {
      setSubmitting(true);
      const token = await handleReCaptchaVerify();
      const username = `+${form.prefix.value}${form.phone.value}`;
      const password = form.pin.value;
      const response = await api.createAccount({
        challenge: token,
        phone_number: username,
        password,
        first_name: form.name.value,
        last_name: form.lastName.value,
        email: form.email.value
      });
      if (response.success) {
        context.setData({ auth: { ready: true, isSignedIn: false, tempCredentials: { username, password } } });
        history.push('/signup/verify');
      } else if (response.errors?.phoneInUse) {
        setAlertMessage({ title: 'PHONE_IN_USE_TITLE', body: 'PHONE_IN_USE_BODY' });
        setShowAlert(true);
      }
    }, (e) => {
      if (e) {
        setAlertMessage({ title: 'GENERIC_ERROR_TITLE', body: 'GENERIC_ERROR_BODY' });
        setShowAlert(true);
      }
      setSubmitting(false);
    });
  }

  return (
    <SignupWizard
      canGoNext={conditionsAccepted && privacyPolicyAccepted && !submitting}
      loading={submitting}
      next={submit}
      step={SingupWizardStep.SIGNUP}
    >
      <React.Fragment>
        <AlertDialog
          open={showAlert}
          onClose={() => setShowAlert(false)}
          title={t(alertMessage.title)}
          message={t(alertMessage.body)}
        />
        <Grid container spacing={2}>
          {!loadingCountries &&
            <React.Fragment>
              <Grid item xs={12}>
                <Typography>
                  {t('SIGNUP_INSTRUCTIONS')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={t('NAME')}
                  variant='outlined'
                  fullWidth={true}
                  value={form.name.value}
                  error={form.name.errorState}
                  helperText={form.name.helperText}
                  onChange={handleFormChange}
                  name={'name'}
                  type='text'
                  inputProps={{
                    autoComplete: 'chrome-off',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={t('LAST_NAME')}
                  variant='outlined'
                  fullWidth={true}
                  value={form.lastName.value}
                  error={form.lastName.errorState}
                  helperText={form.lastName.helperText}
                  onChange={handleFormChange}
                  name={'lastName'}
                  type='text'
                  inputProps={{
                    autoComplete: 'chrome-off',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t('EMAIL')}
                  variant='outlined'
                  fullWidth={true}
                  value={form.email.value}
                  error={form.email.errorState}
                  helperText={form.email.helperText}
                  onChange={handleFormChange}
                  name={'email'}
                  type='text'
                  inputProps={{
                    autoComplete: 'chrome-off',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  onChange={(event, value) => {
                    selectCountry(value);
                  }}
                  value={selectedCountry}
                  options={countries}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => `+${option.prefix} - ${t('countries:' + option.iso3)}`}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderOption={(option) => (
                    <React.Fragment>
                      <CircleFlag countryCode={option.iso2.toLowerCase()} height={25} cdnUrl="/" />
                      <span style={{ marginLeft: 10 }}>{`+${option.prefix}`} - {t('countries:' + option.iso3)}</span>
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('PREFIX')}
                      variant='outlined'
                      error={form.prefix.errorState}
                      helperText={form.prefix.helperText}
                      fullWidth={true}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: selectedCountry ? (
                          <CircleFlag countryCode={selectedCountry.iso2.toLowerCase()} height={25} cdnUrl="/" />
                        ) : undefined
                      }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'chrome-off',
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  label={t('PHONE_NUMBER')}
                  value={form.phone.value}
                  error={form.phone.errorState}
                  helperText={form.phone.helperText}
                  onChange={handleFormChange}
                  variant='outlined'
                  name={'phone'}
                  fullWidth={true}
                  inputProps={{
                    autoComplete: 'chrome-off',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t('PIN')}
                  variant='outlined'
                  fullWidth={true}
                  value={form.pin.value}
                  error={form.pin.errorState}
                  helperText={form.pin.errorState ? form.pin.helperText : t('PIN_HELP')}
                  onChange={handleFormChange}
                  name={'pin'}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => { setShowPassword(!showPassword) }}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  inputProps={{
                    autoComplete: 'chrome-off',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={conditionsAccepted}
                      onChange={() => { setConditionsAccepted(!conditionsAccepted) }}
                      color='primary'
                    />
                  }
                  label={
                    <Trans i18nKey={'ACCEPT_CONDITIONS'} t={t}>
                      <Typography>
                        ACCEPT <a style={{ color: 'black' }} href={i18n.language === 'es' ? 'https://about.zendy.tel/legal.html' : 'https://about.zendy.tel/legal.html'} target="_blank">CONDITIONS</a>
                      </Typography>
                    </Trans>}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={privacyPolicyAccepted}
                      onChange={() => { setPrivacyPolicyAccepted(!privacyPolicyAccepted) }}
                      color='primary'
                    />
                  }
                  label={
                    <Trans i18nKey={'ACCEPT_PRIVACY'} t={t}>
                      <Typography>
                        ACCEPT <a style={{ color: 'black' }} href={i18n.language === 'es' ? 'https://about.zendy.tel/privacy.html' : 'https://about.zendy.tel/privacy.html'} target="_blank">PRIVACY</a>
                      </Typography>
                    </Trans>}
                />
              </Grid>
            </React.Fragment>
          }
          {loadingCountries &&
            <Grid item xs={12}>
              <Grid container justify='center'>
                <CircularProgress />
              </Grid>
            </Grid>
          }
        </Grid>
      </React.Fragment>
    </SignupWizard>
  );
}



import React, { useCallback } from 'react';
import { Grid, Typography, CircularProgress, TextField, makeStyles } from '@material-ui/core';
import { SignupWizard, SingupWizardStep } from './wizard';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useApi, useAuth, useContext } from '../context';
import { ConfirmationDialog } from '../components/confirmationDialog';
import utils from '../services/utils.service';
import { AlertDialog } from '../components/alertDialog';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

type FormField = {
  value: string;
  errorState: boolean;
  helperText: string;
}

const useStyle = makeStyles((theme) => ({
  resendLink: {
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}));

export const Verify = () => {
  const { t, i18n } = useTranslation(['signup']);
  const history = useHistory();
  const context = useContext();
  const api = useApi();
  const auth = useAuth();

  const [loading, setLoading] = React.useState(false);
  const [resendError, setResendError] = React.useState(false);
  const [verifyError, setVerifyError] = React.useState(false);
  const [loginError, setLoginError] = React.useState(false);

  const [form, updateForm] = React.useReducer((
    state: { [key: string]: FormField },
    action: { field: string, data: Partial<FormField> }
  ): { [key: string]: FormField } => {
    return {
      ...state,
      [action.field]: {
        ...state[action.field],
        ...action.data
      }
    };
  },
    {
      verificationCode: {
        value: '',
        errorState: false,
        helperText: ''
      },
    }
  );

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateForm({ field: e.target.name, data: { value: e.target.value, errorState: false, helperText: '' } });
  }

  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);

  React.useEffect(() => {
    if (!context.data.auth.tempCredentials) {
      history.push('/signup');
    }
  }, []);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      throw new Error('Recaptcha not yet available.')
    }
    const token = await executeRecaptcha('resendSms');
    if(!token){
      throw new Error('Could not get token.');
    }
    return token;
  }, [executeRecaptcha]);

  const resendCode = () => {
    utils.runAsync(async () => {
      setLoading(true);
      const token = await handleReCaptchaVerify();
      await api.resendCode(context.data.auth.tempCredentials!.username,token);
    }, (e) => {
      if (e) {
        setResendError(true);
      }
      setShowConfirmDialog(false);
      setLoading(false);
    });
  }

  const verify = () => {
    if (form.verificationCode.value.length === 0) {
      return;
    }
    setLoading(true);
    utils.runAsync(async () => {
      await api.verifyAccount(
        context.data.auth.tempCredentials!.username,
        form.verificationCode.value,
        i18n.language.toLowerCase().includes('es') ? 'es' : 'en'
      );
    }, (verificationError) => {
      if (verificationError) {
        setVerifyError(true);
        setLoading(false);
        return;
      }
      utils.runAsync(async () => {
        const loginResponse = await auth.login(
          context.data.auth.tempCredentials!.username,
          context.data.auth.tempCredentials!.password,
          false
        );
        if (loginResponse.valid_credentials) {
          history.push('/signup/profile');
        } else {
          setLoginError(true);
        }
      }, (loginError) => {
        setLoading(false);
        if (loginError) {
          setLoginError(true);
        }
      });
    });
  }

  const classes = useStyle();

  return (
    <SignupWizard
      canGoNext={form.verificationCode.value.length > 5}
      loading={loading}
      next={verify}
      step={SingupWizardStep.VERIFY_ACCOUNT}
    >
      <React.Fragment>
        <AlertDialog
          open={resendError}
          title={t('RESEND_CODE_ERROR_TITLE')}
          message={t('RESEND_CODE_ERROR_BODY')}
          onClose={() => { setResendError(false) }}
        />
        <AlertDialog
          open={verifyError}
          title={t('VERIFY_ERROR_TITLE')}
          message={t('VERIFY_ERROR_BODY')}
          onClose={() => { setVerifyError(false) }}
        />
        <AlertDialog
          open={loginError}
          title={t('LOGIN_ERROR_TITLE')}
          message={t('LOGIN_ERROR_BODY')}
          onClose={() => {
            setLoginError(false);
            history.push('/account');
          }}
        />
        <ConfirmationDialog
          open={showConfirmDialog}
          title={t('RESEND_CODE_CONFIRMATION_TITLE')}
          message={t('RESEND_CODE_CONFIRMATION_BODY', { phone: context.data.auth.tempCredentials?.username })}
          onConfirm={resendCode}
          loading={loading}
          onCancel={() => { setShowConfirmDialog(false) }}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              {t('VERIFY_INSTRUCTIONS', { phone: context.data.auth.tempCredentials?.username })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('VERIFICATION_CODE')}
              variant='outlined'
              fullWidth={true}
              value={form.verificationCode.value}
              error={form.verificationCode.errorState}
              helperText={form.verificationCode.helperText}
              onChange={handleFormChange}
              name={'verificationCode'}
              type={'text'}
              inputProps={{
                autoComplete: 'chrome-off',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Trans i18nKey={'RESEND_CODE_INSTRUCTIONS'} t={t}>
              <Typography>
                REQUEST <a className={classes.resendLink} onClick={() => setShowConfirmDialog(true)}>NEW CODE.</a>
              </Typography>
            </Trans>

          </Grid>
        </Grid>
      </React.Fragment>
    </SignupWizard>
  );
}



import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import InfoIcon from '@material-ui/icons/Info';
import PinDropIcon from '@material-ui/icons/PinDrop';
import remittanceImg from '../assets/img/remittance.svg';
import { Box, Button, ButtonGroup, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemText, TablePagination, TextField, Theme, Typography } from '@material-ui/core';
import utils from '../services/utils.service';
import { useApi } from '../context';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Transaction } from '../services/types/transaction.type';
import { Autocomplete } from '@material-ui/lab';
import { LoadingButton } from '../components/loadingButton';
import { AlertDialog } from '../components/alertDialog';
import { TransactionTypeCode } from '../services/types/transactionType.type';
import { LocationsModal } from '../locations/locationsModal';

const useStyles = makeStyles((theme: Theme) => ({
  img: {
    maxWidth: '90%',
    width: 300,
  },
  locationButtonListItem: {
    padding: theme.spacing(0.25, 1),
    marginTop: theme.spacing(2),
    justifyContent: 'center'
  },
  listItem: {
    padding: theme.spacing(0.25, 1),
  },
  listItemText: {
    margin: theme.spacing(0)
  }
}));

export const TransactionDetails = ({ transaction, onClose }: { transaction: Transaction | null, onClose: () => void }) => {
  const { t } = useTranslation(['account', 'deliveryMethod', 'accountType', 'documentTypes']);
  const api = useApi();
  const classes = useStyles();
  const [showSupport, setShowSupport] = React.useState(false);
  const [reason, selectReason] = React.useState<string | null>(null);
  const [message, setMessage] = React.useState('');
  const [showMessageSend, setShowMessageSend] = React.useState(false);
  const [showLocationsModal, setShowLocationsModal] = React.useState(false);
  const [sendingSupportMessage, setSendingSupportMessage] = React.useState(false);

  const reasons = ['DELIVERY_ERROR', 'TRANSFER_DELAYED', 'REFUND_NOT_RECEIVED', 'GENERAL_INFO'];

  React.useEffect(() => {
    selectReason(null);
    setMessage('');
  }, [showSupport]);

  const sendSupportMessage = () => {
    if (message.length > 0 && reason !== null) {
      utils.runAsync(async () => {
        setSendingSupportMessage(true);
        await api.sendSupportMessage(reason, message, transaction!.id);
      }, (e) => {
        setSendingSupportMessage(false);
        if (!e) {
          setShowSupport(false);
          setShowMessageSend(true);
        }
      });
    }
  }

  if (!transaction) return (<></>);
  return (
    <>
      <LocationsModal
        open={showLocationsModal}
        onClose={() => setShowLocationsModal(false)}
        agentId={transaction.product.transaction_type.code === TransactionTypeCode.CASH_TO_AGENT ? transaction.product.agent.id : undefined}
        amount={transaction.receive_amount}
        transactionTypeId={transaction.product.transaction_type.id}
        countryId={transaction.product.country.id}
        currencyId={transaction.product.currency.id}
      />
      <AlertDialog open={showMessageSend} onClose={() => setShowMessageSend(false)} title={t('MESSAGE_SUCCESS_TITLE')} message={t('MESSAGE_SUCCESS_DESCRIPTION')} />
      <Dialog fullWidth open={showSupport} onClose={() => setShowSupport(false)}>
        <DialogTitle>{t('SUPPORT_TITLE')}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                onChange={(event, value) => {
                  selectReason(value);
                }}
                value={reason}
                options={reasons}
                autoHighlight
                getOptionLabel={(option) => t(option)}
                renderOption={(option) => t(option)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('PICK_REASON')}
                    variant='outlined'
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'chrome-off',
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('MESSAGE')}
                fullWidth
                multiline
                rows={10}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                variant='outlined'
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowSupport(false)}>
            {t('CANCEL')}
          </Button>
          <LoadingButton
            disabled={sendingSupportMessage || (message.length === 0 || reason === null)}
            loading={sendingSupportMessage}
            onClick={sendSupportMessage}
            color='primary'>
            {t('SEND')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={transaction !== null}
        onClose={onClose}
        scroll={'paper'}
      >
        <DialogTitle>{t('TRANSACTION_DETAILS_TITLE')}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                {t('Delivery')}
              </Typography>
              <Grid item xs={12} container>
                <Grid item xs={12} sm={6}>
                  <List disablePadding>
                    <ListItem className={classes.listItem}>
                      <ListItemText primary={t('ZENDY_REFERENCE')} secondary={transaction.reference} className={classes.listItemText} />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <ListItemText primary={t('Reference')} secondary={utils.getOrDefault(transaction.external_reference,'-')} className={classes.listItemText} />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <ListItemText primary={t('Country')} secondary={transaction.product.country.name} className={classes.listItemText} />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <ListItemText
                        primary={t('Amount')}
                        secondary={`${utils.currencyFormat(transaction.receive_amount)} ${transaction.product.currency.iso}`} className={classes.listItemText}
                      />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <ListItemText
                        primary={t('Delivery method')}
                        secondary={t(`deliveryMethod:${transaction.product.transaction_type.code}`)} className={classes.listItemText}
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <List disablePadding>
                    {transaction.product.transaction_type.code !== TransactionTypeCode.CASH &&
                      <ListItem className={classes.listItem}>
                        <ListItemText primary={t('Agent')} secondary={transaction.product.agent.name} className={classes.listItemText} />
                      </ListItem>
                    }

                    {transaction.location &&
                      <ListItem className={classes.listItem}>
                        <ListItemText
                          primary={t('Branch')}
                          secondary={transaction.location?.name}
                          className={classes.listItemText}
                        />
                      </ListItem>
                    }
                    {transaction.receiver.account_fields && transaction.receiver.account_type &&
                      <ListItem className={classes.listItem}>
                        <ListItemText
                          primary={t('Account')}
                          secondary={`${t(`accountType:${transaction.receiver.account_type.name}`)} ${transaction.receiver.account_fields.map(i => i.value).join(' - ')}`}
                          className={classes.listItemText}
                        />
                      </ListItem>
                    }
                    <ListItem className={classes.listItem}>
                      <ListItemText primary={t('Status')} secondary={t(transaction.status)} className={classes.listItemText} />
                    </ListItem>
                    {[
                      TransactionTypeCode.CASH,
                      TransactionTypeCode.CASH_TO_AGENT,
                      TransactionTypeCode.ATM
                    ].includes(transaction.product.transaction_type.code) &&
                      <ListItem className={classes.locationButtonListItem}>
                        <Button
                          startIcon={<PinDropIcon />}
                          variant='outlined'
                          color='secondary'
                          onClick={() => setShowLocationsModal(true)}>
                          {t('VIEW_PICKUP_LOCATIONS_BUTTON_TITLE')}
                        </Button>
                      </ListItem>
                    }
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                {t('Beneficiary')}
              </Typography>
              <Grid item xs={12} container>
                <Grid item xs={12} sm={6}>
                  <List disablePadding>
                    <ListItem className={classes.listItem}>
                      <ListItemText
                        primary={t('Identification')}
                        secondary={
                          <>
                            <Typography variant='body2'>{`${transaction.receiver.first_name} ${transaction.receiver.last_name}`}</Typography>
                            <Typography variant='body2'>{transaction.receiver.id_card_country ? transaction.receiver.id_card_country.name : ''}</Typography>
                            <Typography variant='body2'>{`${t(`documentTypes:${transaction.receiver.id_card_type}`)} ${transaction.receiver.id_card_number}`}</Typography>
                            <Typography variant='body2'>{`${t('T_ISSUED')} ${transaction.receiver.id_card_issue_date ? moment(transaction.receiver.id_card_issue_date).format('MM-YYYY') : ''}`}</Typography>
                            <Typography variant='body2'>{`${t('EXPIRES')} ${transaction.receiver.id_card_expiry_date ? moment(transaction.receiver.id_card_expiry_date).format('MM-YYYY') : ''}`}</Typography>
                          </>
                        }
                        className={classes.listItemText}
                      />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <ListItemText
                        primary={t('Contact data')}
                        secondary={
                          <>
                            <Typography variant='body2'>{transaction.receiver.phone}</Typography>
                            <Typography variant='body2'>{transaction.receiver.email}</Typography>
                          </>
                        }
                        className={classes.listItemText} />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <List disablePadding>
                    <ListItem className={classes.listItem}>
                      <ListItemText
                        primary={t('Address')}
                        secondary={
                          <>
                            <Typography variant='body2'>{transaction.receiver.address}</Typography>
                            <Typography variant='body2'>{`${transaction.receiver.postal_code ? transaction.receiver.postal_code + ',' : ''} ${transaction.receiver.city}, ${transaction.receiver.region}`}</Typography>
                            <Typography variant='body2'>{transaction.receiver.residence_country ? transaction.receiver.residence_country.name : ''}</Typography>
                          </>
                        }
                        className={classes.listItemText} />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <ListItemText primary={t('Concept')} secondary={transaction.concept} className={classes.listItemText} />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                {t('Payment')}
              </Typography>
              <List disablePadding>
                <ListItem className={classes.listItem}>
                  <ListItemText primary={t('SEND_AMOUNT')} />
                  <Typography component='span'>{`${utils.currencyFormat(transaction.send_amount)} ${transaction.source_currency.iso}`}</Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemText primary={t('COST')} />
                  <Typography component='span'>{`${utils.currencyFormat(transaction.amount - transaction.send_amount)} ${transaction.source_currency.iso}`}</Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemText primary={t('EXCHANGE_RATE')} />
                  <Typography component='span'>{`${utils.currencyFormat(1,2)} ${transaction.source_currency.iso} = ${utils.currencyFormat(transaction.receive_amount/transaction.send_amount,4)} ${transaction.product.currency.iso}`}</Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemText primary={t('PAYMENT_AMOUNT')} />
                  <Typography component='span'>{`${utils.currencyFormat(transaction.amount)} ${transaction.source_currency.iso}`}</Typography>
                </ListItem>
                {transaction.reward > 0 &&
                  <ListItem className={classes.listItem}>
                    <ListItemText primary={t('Reward')} />
                    <Typography component='span'>{`${utils.currencyFormat(transaction.reward)} ${transaction.source_currency.iso}`}</Typography>
                  </ListItem>
                }
                {transaction.promotion_amount &&
                  <ListItem className={classes.listItem}>
                    <ListItemText primary={t('Promotion')} />
                    <Typography component='span'>{`${utils.currencyFormat(transaction.promotion_amount!)} ${transaction.source_currency.iso}`}</Typography>
                  </ListItem>
                }
                {transaction.refunded_on &&
                  <ListItem className={classes.listItem}>
                    <ListItemText primary={t('Refunded on')} />
                    <Typography component='span'>{moment(transaction.refunded_on).format('lll')}</Typography>
                  </ListItem>
                }
              </List>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => { setShowSupport(true) }} variant='outlined' startIcon={<LiveHelpIcon />}>
            {t('SUPPORT')}
          </Button> */}
          <Button onClick={onClose} color="primary">
            {t('OK')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export const Transactions = () => {
  const classes = useStyles();
  const api = useApi();

  const { t, i18n } = useTranslation(['account', 'deliveryMethod']);
  moment.locale(i18n.language);

  const [loading, setLoading] = React.useState(true);
  const [transactions, setTransactions] = React.useState<any[]>([]);
  const [detailTransaction, setDetailTransaction] = React.useState<Transaction | null>(null);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const limit = 10;

  React.useEffect(() => {
    utils.runAsync(async () => {
      setLoading(true);
      const { count, transactions } = await api.getTransactions(limit, page * limit);
      setCount(count);
      setTransactions(transactions);
    }, () => {
      setLoading(false);
    });
  }, [page]);

  return (
    <>
      <TransactionDetails transaction={detailTransaction} onClose={() => { setDetailTransaction(null) }} />
      <Paper>
        <Box paddingTop={2} paddingLeft={1} paddingBottom={1}>
          <Typography variant="h5">
            {t('TRANSACTIONS')}
          </Typography>
        </Box>
        {loading &&
          <Grid item container justify='center'>
            <Box padding={4}>
              <CircularProgress />
            </Box>
          </Grid>
        }
        {!loading && transactions.length > 0 &&
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>{t('DATE')}</TableCell>
                    <TableCell>{t('STATUS')}</TableCell>
                    <TableCell>{t('BENEFICIARY')}</TableCell>
                    <TableCell>{t('COUNTRY')}</TableCell>
                    <TableCell>{t('DELIVERY')}</TableCell>
                    <TableCell>{t('AMOUNT')}</TableCell>
                    <TableCell>{t('COST')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.map(tr => (
                    <TableRow key={tr.id}>
                      <TableCell>
                        <ButtonGroup variant='text' color='secondary'>
                          <Button onClick={() => { setDetailTransaction(tr) }}>
                            <InfoIcon fontSize="small" />
                          </Button>
                        </ButtonGroup>
                      </TableCell>
                      <TableCell>{moment(tr.created).format('lll')}</TableCell>
                      <TableCell><Chip size='small' color={tr.refunded_on || ['ACCEPTED', 'DELIVERED'].includes(tr.status) ? 'primary' : 'default'} label={tr.refunded_on ? t('REFUNDED') : t(tr.status)} /></TableCell>
                      <TableCell>{`${tr.receiver.first_name} ${tr.receiver.last_name}`}</TableCell>
                      <TableCell>{tr.product.country.name}</TableCell>
                      <TableCell>{t(`deliveryMethod:${tr.product.transaction_type.code}`)}</TableCell>
                      <TableCell>{`${utils.currencyFormat(tr.receive_amount)} ${tr.product.currency.iso}`}</TableCell>
                      <TableCell>{`${utils.currencyFormat(tr.amount)} ${tr.source_currency.iso}`}</TableCell>
                    </TableRow>

                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component='div'
              rowsPerPageOptions={[limit]}
              count={count}
              rowsPerPage={limit}
              page={page}
              onChangePage={(event, page) => { setPage(page) }}
            />
          </>
        }
        {!loading && transactions.length === 0 &&
          <Box padding={4}>
            <Grid container spacing={1} justify='center'>
              <Grid item>
                <img src={remittanceImg} className={classes.img} />
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom variant='subtitle2' align='center'>
                  {t('NO_TRANSACTIONS')}
                </Typography>
              </Grid>
              <Grid item>
                <Button component={Link} to='/transfer' variant='outlined' size='large' color='secondary'>{t('NEW_TRANSFER')}</Button>
              </Grid>
            </Grid>
          </Box>
        }
      </Paper>
    </>
  );
}
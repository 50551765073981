import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography, TextField, InputAdornment, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../components/loadingButton';
import utils from '../services/utils.service';
import { useApi } from '../context';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const pinRegexp = new RegExp('^[0-9]{4}$');

export const UpdatePassword = ({ onClose, open }: { email?: string, onClose: () => void, open: boolean }) => {
  const { t } = useTranslation('account');
  const api = useApi();

  const [newPin, setNewPin] = React.useState('');
  const [oldPin, setOldPin] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [pinError, setPinError] = React.useState(false);

  const [showPassword, setShowPassword] = React.useState(false);

  React.useEffect(() => {
    setNewPin('');
    setOldPin('');
    setPinError(false);
    setShowPassword(false);
  }, [open]);

  const submit = () => {
    if (!pinRegexp.test(newPin)) {
      setPinError(true);
      return;
    }
    utils.runAsync(async () => {
      setLoading(true);
      await api.updatePin(oldPin, newPin);
      onClose();
    }, (e) => {
      if (e) {
        console.error(e);
      }
      setLoading(false);
    })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog'
    >
      <DialogTitle id='dialog-title'>{t('UPDATE_PIN_TITLE')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              {t('UPDATE_PIN_INSTRUCTIONS')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('OLD_PIN')}
              variant='outlined'
              fullWidth={true}
              value={oldPin}
              onChange={(e) => {
                setOldPin(e.target.value)
              }}
              type='password'
              inputProps={{
                autoComplete: 'chrome-off',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('NEW_PIN')}
              variant='outlined'
              fullWidth={true}
              value={newPin}
              onChange={(e) => {
                setPinError(false);
                setNewPin(e.target.value)
              }}
              error={pinError}
              helperText={pinError ? t('WRONG_PIN_FORMAT') : ''}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => { setShowPassword(!showPassword) }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              inputProps={{
                autoComplete: 'chrome-off',
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton loading={loading} disabled={loading || !oldPin} onClick={submit} color='primary' variant='outlined' autoFocus>
          {t('UPDATE')}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  )
}
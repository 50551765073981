import React from 'react';
import { Grid, Typography, CircularProgress, makeStyles, TextField, Button } from '@material-ui/core';
import { SignupWizard, SingupWizardStep } from './wizard';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import { useApi, useAuth, useContext } from '../context';
import { Moment } from 'moment';
import { Country } from '../services/types/country.type';
import utils from '../services/utils.service';
import { Alert, Autocomplete } from '@material-ui/lab';
import { DatePicker } from '@material-ui/pickers';
import { LoadingButton } from '../components/loadingButton';
import frontImagePlaceholder from '../assets/img/id_frente.svg';
import backImagePlaceholder from '../assets/img/id_dorso.svg';
import kycValidated from '../assets/img/kyc_verified.svg';
import kycProcessing from '../assets/img/kyc_processing.svg';
import { AlertDialog } from '../components/alertDialog';
import { UserProfile } from '../services/types/userProfile.type';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  avatar: {
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  avatarIcon: {
    fontSize: 82
  }
}));

type FormField = {
  value: string;
  errorState: boolean;
  helperText: string;
}

export const Kyc = () => {
  const { t } = useTranslation(['signup','countries']);
  const history = useHistory();
  const context = useContext();
  const api = useApi();
  const auth = useAuth();

  const [form, updateForm] = React.useReducer((
    state: { [key: string]: FormField },
    action: { field: string, data: Partial<FormField> }
  ): { [key: string]: FormField } => {
    return {
      ...state,
      [action.field]: {
        ...state[action.field],
        ...action.data
      }
    };
  },
    {
      country: {
        value: '',
        errorState: false,
        helperText: ''
      },
      documentType: {
        value: '',
        errorState: false,
        helperText: ''
      },
      expiryDate: {
        value: '',
        errorState: false,
        helperText: ''
      },
      idNumber: {
        value: '',
        errorState: false,
        helperText: ''
      },
    }
  );

  const [loadingProfile, setLoadingProfile] = React.useState(true);

  const [loadingCountries, setLoadingCountries] = React.useState(true);
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [selectedCountry, setSelectedCountry] = React.useState<Country | null>(null);

  const [expiryDate, setExpiryDate] = React.useState<Moment | null>(null);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [uploadingFrontFile, setUploadingFrontFile] = React.useState(false);
  const [uploadingBackFile, setUploadingBackFile] = React.useState(false);

  const [frontFile, setFrontFile] = React.useState<File | undefined>(undefined);
  const [frontFileId, setFrontFileId] = React.useState<string | null>(null);
  const [backFile, setBackFile] = React.useState<File | undefined>(undefined);
  const [backFileId, setBackFileId] = React.useState<string | null>(null);

  const [documentType, setDocumentType] = React.useState<string | null>(null);

  const [submitting, setSubmitting] = React.useState(false);

  const [profile, setProfile] = React.useState<UserProfile | null>(null);

  React.useEffect(() => {
    utils.runAsync(async () => {
      setCountries(await api.getCountries());
    }, (countriesError) => {
      setLoadingCountries(false);
    });
  }, []);

  React.useEffect(() => {
    if (context.data.auth.isSignedIn) {
      loadProfile();
    }
  }, [context.data.auth.isSignedIn]);

  const loadProfile = () => {
    utils.runAsync(async () => {
      setLoadingProfile(true);
      setProfile(await api.getMyProfile());
    }, (e) => {
      setLoadingProfile(false);
    });
  }

  const logout = () => {
    auth.logout();
    history.push('/account');
  }

  React.useEffect(() => {
    if (profile && !profile.profile.complete) {
      history.push('/signup/profile');
    }
  }, [profile]);

  const onFile = (type: 'front' | 'back', event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length && event.target.files.length > 0) {
      const file = event.target.files[0]
      if (file.size > 5000000) {
        setError('MAX_FILE_SIZE_EXCEEDED');
        return;
      }
      utils.runAsync(async () => {
        if (type === 'front') {
          setUploadingFrontFile(true);
        } else {
          setUploadingBackFile(true);
        }
        const { signedUrl, fileId } = await api.getUploadUrl(file.type)
        await api.uploadFile(file, signedUrl);
        if (type === 'front') {
          setFrontFile(file);
          setFrontFileId(fileId);
        } else {
          setBackFile(file);
          setBackFileId(fileId);
        }
      }, (e) => {
        if (e) {
          setError('COULD_NOT_UPLOAD_FILE');
        }
        if (type === 'front') {
          setUploadingFrontFile(false);
        } else {
          setUploadingBackFile(false);
        }
      });
    } else {
      if (type === 'front') {
        setFrontFile(undefined);
      } else {
        setBackFile(undefined);
      }
    }
  }

  const validate = () => {
    let isValid = true;
    if (!selectedCountry) {
      isValid = false;
      updateForm({ field: 'country', data: { errorState: true, helperText: 'REQUIRED' } });
    }
    if (!documentType) {
      isValid = false;
      updateForm({ field: 'documentType', data: { errorState: true, helperText: 'REQUIRED' } });
    }
    if (!expiryDate) {
      isValid = false;
      updateForm({ field: 'expiryDate', data: { errorState: true, helperText: 'REQUIRED' } });
    }
    if (documentType && documentType === 'NATIONAL_ID_CARD' && form.idNumber.value.length === 0) {
      isValid = false;
      updateForm({ field: 'idNumber', data: { errorState: true, helperText: 'REQUIRED' } });
    }
    if (documentType && documentType === 'NATIONAL_ID_CARD' && !backFileId) {
      isValid = false;
    }
    if (!frontFileId) {
      isValid = false;
    }
    return isValid;
  }

  const submit = () => {
    if (!validate()) {
      if (expiryDate && documentType && selectedCountry) {
        if (!frontFileId) {
          setError('DOCUMENT_UPLOAD_REQUIRED');
        }
        if (documentType && documentType === 'NATIONAL_ID_CARD' && !backFileId) {
          setError('DOCUMENT_UPLOAD_REQUIRED');
        }
      }
      return;
    }
    utils.runAsync(async () => {
      setSubmitting(true);
      await api.addKyc(
        {
          front_image_file_id: frontFileId!,
          back_image_file_id: backFileId ? backFileId : undefined,
          country_id: selectedCountry!.id,
          type: documentType === 'PASSPORT' ? 'PASSPORT' : 'NATIONAL_ID_CARD',
          expiry_date: expiryDate!.format('YYYY-MM-DD'),
          number: documentType === 'NATIONAL_ID_CARD' ? form.idNumber.value : undefined
        }
      );
    }, (e) => {
      setSubmitting(false);
      window.scrollTo(0, 0);
      if (e) {
        setError('COULD_NOT_ADD_KYC')
      } else {
        setFrontFile(undefined);
        setBackFile(undefined);
        setFrontFileId(null);
        setBackFileId(null);
        updateForm({ field: 'idNumber', data: { errorState: false, helperText: '', value: '' } });
        setSelectedCountry(null);
        setDocumentType(null);
        setExpiryDate(null);
        loadProfile();
      }
    });
  }

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateForm({ field: e.target.name, data: { value: e.target.value, errorState: false, helperText: '' } });
  }


  const classes = useStyles();

  if (!context.data.auth.ready) {
    return (
      <Grid container justify='center'>
        <CircularProgress />
      </Grid>
    )
  } else {
    if (!context.data.auth.isSignedIn) {
      return <Redirect to='/account' />
    }
  }

  return (
    <SignupWizard
      canGoNext={frontFileId !== null}
      showButtons={(
        (profile?.kyc.valid || profile?.kyc.status === 'PROCESSING') || (profile?.kyc.status === 'VALIDATED' && !profile?.kyc.valid)
      ) ? false : true}
      loading={submitting}
      next={submit}
      step={SingupWizardStep.KYC}
    >
      <React.Fragment>
        <AlertDialog
          onClose={() => setError(undefined)}
          open={error !== undefined}
          title={t('GENERIC_ERROR_TITLE')}
          message={t(error ? error : 'GENERIC_ERROR_TITLE')}
        />

        <Grid container spacing={2}>
          {(!loadingCountries && !loadingProfile) &&
            <>
              {profile?.kyc.valid &&
                <>
                  <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <Typography variant={'h5'} align={'center'}>
                      {t('KYC_VALIDATED')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justify='center' alignItems='center' alignContent='center' spacing={4}>
                      <Grid item xs={12}>
                        <img style={{ margin: '0 auto', maxWidth: '100%',width:'250px', display: 'block' }} src={kycValidated} />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container justify='center' spacing={4}>
                          <Grid item>
                            <Button
                              component={RouterLink}
                              to='/transfer'
                              variant='contained'
                              size={'large'}
                              color={'primary'}>
                              {t('NEW_TRANSFER')}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              }
              {((profile?.kyc.status === 'PROCESSING') || (profile?.kyc.status === 'VALIDATED' && !profile?.kyc.valid)) &&
                <>
                  <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <Typography>
                      {t('KYC_PROCESSING')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justify='center' alignItems='center' alignContent='center' spacing={2}>
                      <Grid item xs={12}>
                        <img style={{ margin: '0 auto', maxWidth: '100%', width:'250px', display: 'block' }} src={kycProcessing} />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container justify='center' spacing={4}>
                          <Grid item>
                            <Button
                              variant='contained'
                              onClick={loadProfile}
                              size={'large'}
                              color={'primary'}>
                              {t('REFRESH')}
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant='outlined'
                              onClick={logout}
                              size={'large'}
                              color={'secondary'}>
                              {t('LOGOUT')}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              }
              {(profile?.kyc.status === 'PENDING' || profile?.kyc.status === 'REJECTED') &&
                <>
                  {profile?.kyc.status === 'REJECTED' &&
                    <Grid item xs={12}>
                      <Alert severity='warning'>
                        {t('KYC_REJECTED_INSTRUCTIONS')}
                      </Alert>
                    </Grid>
                  }
                  <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <Typography>
                      {t('KYC_INSTRUCTIONS')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} >
                    <Autocomplete
                      onChange={(event, value) => {
                        updateForm({ field: 'country', data: { errorState: false, helperText: '' } });
                        setSelectedCountry(value);
                      }}
                      getOptionSelected={(option, value) => option.id === value.id}
                      value={selectedCountry}
                      options={countries}
                      classes={{
                        option: classes.option,
                      }}
                      autoHighlight
                      getOptionLabel={(option) => t('countries:'+option.iso3)}
                      renderOption={(option) => t('countries:'+option.iso3)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('DOCUMENT_ISSUING_COUNTRY')}
                          variant='outlined'
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'chrome-off',
                          }}
                          error={form.country.errorState}
                          helperText={t(form.country.helperText)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      onChange={(event, value) => {
                        updateForm({ field: 'documentType', data: { errorState: false, helperText: '' } });
                        setDocumentType(value);
                      }}
                      getOptionSelected={(option, value) => option === value}
                      value={documentType}
                      options={['NATIONAL_ID_CARD', 'PASSPORT']}
                      classes={{
                        option: classes.option,
                      }}
                      autoHighlight
                      getOptionLabel={(option) => t(option)}
                      renderOption={(option) => t(option)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('DOCUMENT_TYPE')}
                          variant='outlined'
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'chrome-off',
                          }}
                          error={form.documentType.errorState}
                          helperText={form.documentType.errorState ? t(form.documentType.helperText) : documentType === 'NATIONAL_ID_CARD' ? t('ONLY_PHOTO_IDS') : ''}
                        />
                      )}
                    />
                  </Grid>
                  {documentType !== null &&
                    <React.Fragment>
                      <Grid item xs={12} md={documentType === 'PASSPORT' ? 12 : 6}>
                        <DatePicker
                          clearable
                          okLabel={t('OK')}
                          cancelLabel={t('CANCEL')}
                          clearLabel={t('CLEAR')}
                          fullWidth={true}
                          openTo='year'
                          label={t('DOCUMENT_EXPIRY_DATE')}
                          format='DD/MM/YYYY'
                          disablePast
                          inputVariant='outlined'
                          value={expiryDate}
                          error={form.expiryDate.errorState}
                          helperText={t(form.expiryDate.helperText)}
                          onChange={(value) => {
                            setExpiryDate(value);
                            updateForm({ field: 'expiryDate', data: { errorState: false, helperText: '' } });
                          }}
                        />
                      </Grid>
                      {documentType === 'NATIONAL_ID_CARD' &&
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth={true}
                            label={t('ID_NUMBER')}
                            variant='outlined'
                            name={'idNumber'}
                            value={form.idNumber.value}
                            error={form.idNumber.errorState}
                            helperText={t(form.idNumber.helperText)}
                            onChange={handleFormChange}
                          />
                        </Grid>
                      }
                      <Grid item xs={12} style={{ marginTop: 20 }}>
                        <Grid container justify='center' alignContent='center' alignItems='center' spacing={2}>
                          <Grid item xs={12} md={documentType === 'PASSPORT' ? 12 : 6}>
                            <Grid container alignItems='center' alignContent='center' direction='column' spacing={2}>
                              <Grid item>
                                <img style={{ maxWidth: '100%', maxHeight: 350 }} src={frontFile ? URL.createObjectURL(frontFile) : frontImagePlaceholder} />
                              </Grid>
                              <Grid item>
                                <LoadingButton
                                  loading={uploadingFrontFile}
                                  disabled={uploadingFrontFile}
                                  component='label'
                                  color='secondary'>
                                  {t('UPLOAD_FRONT_FILE')}
                                  <input
                                    type='file'
                                    accept='image/jpeg'
                                    capture='environment'
                                    onChange={(e) => onFile('front', e)}
                                    hidden />
                                </LoadingButton>
                              </Grid>
                            </Grid>
                          </Grid>
                          {documentType === 'NATIONAL_ID_CARD' &&
                            <Grid item xs={12} md={6}>
                              <Grid container alignItems='center' alignContent='center' direction='column' spacing={2}>
                                <Grid item>
                                  <img style={{ maxWidth: '100%', maxHeight: 350 }} src={backFile ? URL.createObjectURL(backFile) : backImagePlaceholder} />
                                </Grid>
                                <Grid item>
                                  <LoadingButton
                                    loading={uploadingBackFile}
                                    disabled={uploadingBackFile}
                                    component='label'
                                    color='secondary'>
                                    {t('UPLOAD_BACK_FILE')}
                                    <input
                                      type='file'
                                      accept='image/jpeg'
                                      capture='environment'
                                      onChange={(e) => onFile('back', e)}
                                      hidden />
                                  </LoadingButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          }
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  }
                </>
              }
            </>
          }
          {(loadingCountries || loadingProfile) &&
            <Grid item xs={12}>
              <Grid container justify='center'>
                <CircularProgress />
              </Grid>
            </Grid>
          }
        </Grid>
      </React.Fragment>
    </SignupWizard >
  );
}



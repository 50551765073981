import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import Webcam from 'react-webcam';
import utils from '../services/utils.service';

const videoConstraints = {
  facingMode: "user",
  // width: 1024,
  // height: 768
};

export const WebcamDialog = ({
  open,
  onClose,
  onPicture,
}: {
  open: boolean,
  onClose: () => void,
  onPicture: (picture: string) => void,
}) => {

  const [webcamAvailable, setWebcamAvailable] = React.useState<boolean | undefined>(undefined);

  const webcamRef: any = React.useRef(null);

  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      onPicture(imageSrc);
    },
    [webcamRef]
  );


  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      aria-labelledby='webcam-dialog'
    >
      <DialogTitle id='dialog-title'>Subir foto de identidad</DialogTitle>
      <DialogContent>
        {webcamAvailable === undefined &&
          <>Estamos accediendo a tu webcam para que puedas capturar tu foto de identidad. </>
        }
        {webcamAvailable === false &&
          <>Lo sentimos pero no detecamos ninguna webcam. Si crees que sí cuentas con webcam por favor revisa que nos hayas concedido permiso para acceder a ella. Si no cuentas con una webcam debes completar el registro desde un teléfono móvil.</>
        }
        {(webcamAvailable === undefined || webcamAvailable === true) &&
          <Webcam
            audio={false}
            width={'100%'}
            onUserMedia={() => {
              setWebcamAvailable(true);
            }}
            onUserMediaError={() => {
              setWebcamAvailable(false);
            }}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
          />
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='secondary' autoFocus>
          {'Cancelar'}
        </Button>
        <Button onClick={capture} disabled={!webcamAvailable} color='primary' autoFocus>
          {'Tomar foto'}
        </Button>
      </DialogActions>
    </Dialog >
  )
}

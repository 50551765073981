import React, { useCallback } from 'react';
import { Container, createStyles, Grid, makeStyles, Paper, TextField, Theme, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { CircleFlag } from 'react-circle-flags';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../components/loadingButton';
import { useApi } from '../context';
import { Country } from '../services/types/country.type';
import utils from '../services/utils.service';
import { AlertDialog } from '../components/alertDialog';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(3)
    },
    option: {
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
  }),
);

type FormField = {
  value: string;
  errorState: boolean;
  helperText: string;
}

const phoneRegexp = new RegExp('^[0-9]{6,20}$');

export const Username = ({ onSuccess }: { onSuccess: (username: string) => void }) => {
  const { t } = useTranslation(['recoverPassword', 'countries']);
  const classes = useStyles();
  const api = useApi();

  const [form, updateForm] = React.useReducer((
    state: { [key: string]: FormField },
    action: { field: string, data: Partial<FormField> }
  ): { [key: string]: FormField } => {
    return {
      ...state,
      [action.field]: {
        ...state[action.field],
        ...action.data
      }
    };
  },
    {
      prefix: {
        value: '',
        errorState: false,
        helperText: ''
      },
      phone: {
        value: '',
        errorState: false,
        helperText: ''
      },
    }
  );
  const [loadingCountries, setLoadingCountries] = React.useState(true);
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [selectedCountry, selectCountry] = React.useState<Country | null>(null);
  const [submitting, setSubmitting] = React.useState(false);

  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState({ body: '', title: '' });

  React.useEffect(() => {
    utils.runAsync(async () => {
      setCountries(await api.getCountries());
    }, () => {
      setLoadingCountries(false);
    });
  }, []);

  React.useEffect(() => {
    if (selectedCountry) {
      updateForm({ field: 'prefix', data: { value: selectedCountry.prefix, errorState: false, helperText: '' } });
    } else {
      updateForm({ field: 'prefix', data: { value: '', errorState: false, helperText: '' } });
    }
  }, [selectedCountry]);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      throw new Error('Recaptcha not yet available.')
    }
    const token = await executeRecaptcha('recoverPassword');
    if (!token) {
      throw new Error('Could not get token.');
    }
    return token;
  }, [executeRecaptcha]);

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateForm({ field: e.target.name, data: { value: e.target.value, errorState: false, helperText: '' } });
  }
  const validate = () => {
    let isValid = true;
    if (!phoneRegexp.test(form.phone.value)) {
      isValid = false;
      updateForm({ field: 'phone', data: { errorState: true, helperText: t('PHONE_FORMAT_ERROR') } });
    }
    if (!selectedCountry) {
      isValid = false;
      updateForm({ field: 'prefix', data: { errorState: true, helperText: t('REQUIRED') } });
    }
    return isValid;
  }

  const submit = () => {
    if (!validate()) {
      return;
    }

    utils.runAsync(async () => {
      setSubmitting(true);
      const token = await handleReCaptchaVerify();
      const username = `+${form.prefix.value}${form.phone.value}`;
      await api.answerSecurityQuestion(username, token);
      onSuccess(username);
    }, (e) => {
      if (e) {
        setAlertMessage({ title: 'USER_NOT_FOUND_TITLE', body: 'USER_NOT_FOUND_BODY' });
        setShowAlert(true);
      }
      setSubmitting(false);
    });
  }

  return (
    <>
      <AlertDialog
        open={showAlert}
        onClose={() => setShowAlert(false)}
        title={t(alertMessage.title)}
        message={t(alertMessage.body)}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
                {t('RECOVER_INSTRUCTIONS')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                onChange={(event, value) => {
                  selectCountry(value);
                }}
                value={selectedCountry}
                options={countries}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => `+${option.prefix} - ${t('countries:' + option.iso3)}`}
                getOptionSelected={(option, value) => option.id === value.id}
                renderOption={(option) => (
                  <React.Fragment>
                    <CircleFlag countryCode={option.iso2.toLowerCase()} height={25} cdnUrl="/" />
                    <span style={{ marginLeft: 10 }}>{`+${option.prefix}`} - {t('countries:' + option.iso3)}</span>
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('PREFIX')}
                    variant='outlined'
                    error={form.prefix.errorState}
                    helperText={form.prefix.helperText}
                    fullWidth={true}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: selectedCountry ? (
                        <CircleFlag countryCode={selectedCountry.iso2.toLowerCase()} height={25} cdnUrl="/" />
                      ) : undefined
                    }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'chrome-off',
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                label={t('PHONE_NUMBER')}
                value={form.phone.value}
                error={form.phone.errorState}
                helperText={form.phone.helperText}
                onChange={handleFormChange}
                variant='outlined'
                name={'phone'}
                fullWidth={true}
                inputProps={{
                  autoComplete: 'chrome-off',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justify='flex-end'>
            <Grid item>
              <LoadingButton
                variant='contained'
                color='primary'
                disabled={submitting}
                loading={submitting}
                onClick={() => { submit() }}>
                {t('CONTINUE')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )


}
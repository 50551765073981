import React, { useState } from 'react';
import { Grid, Typography, Container, CircularProgress, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import okImg from '../assets/img/success.svg'
import { useApi, useContext } from '../context';
import { useParams } from 'react-router-dom';
import utils from '../services/utils.service';
import { TransactionTypeCode } from '../services/types/transactionType.type';
import { TransactionStatus } from '../services/types/transaction.type';

export const PaymentOk = () => {
  const { t } = useTranslation('payment');

  const [isLoadingTxInfo, setIsLoadingTxInfo] = useState(true);
  const [octUrl, setOctUrl] = useState<string>();

  const { transactionReference } = useParams<{ transactionReference: string }>();
  const context = useContext();

  const api = useApi();

  React.useEffect(() => {
    context.reset();
    const interval = setInterval(() => {
      utils.runAsync(async () => {
        const txInfo = await api.getTransactionInfo(transactionReference);
        if (txInfo.transaction_type_code === TransactionTypeCode.OCT && txInfo.transaction_status === TransactionStatus.ISSUED) {
          const octUrlResponse = await api.getOctUrl(transactionReference);
          setOctUrl(octUrlResponse.url);
          setIsLoadingTxInfo(false);
          clearInterval(interval);
        } else if (txInfo.transaction_type_code !== TransactionTypeCode.OCT) {
          setIsLoadingTxInfo(false);
        }
      });
    }, 2500);
    return () => {
      clearInterval(interval);
    }
  }, []);

  return (
    <Container style={{ height: '100%' }}>
      <Grid container justify='center' alignContent='center' spacing={4} style={{ height: '100%' }}>
        <Grid item xs={12}>
          <Typography variant='h4' align='center'>
            {t('PAYMENT_OK_TITLE')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1' align='center'>
            {t('PAYMENT_OK_SUBTITLE')}
          </Typography>
        </Grid>
        <Grid item xs={12} container justify='center'>
          <img src={okImg} style={{ maxWidth: 200 }} />
        </Grid>
        <Grid item xs={12}>
          <Typography align='center'>
            {t('PAYMENT_OK_DESCRIPTION')}
          </Typography>
        </Grid>
        {isLoadingTxInfo &&
          <Grid container item xs={12} justify='center'>
            <CircularProgress />
          </Grid>
        }
        {octUrl &&
          <Grid container item xs={12} justify='center'>
            <Link href={octUrl} style={{fontSize:'26px',textAlign:'center',textDecoration:'underline'}}>{t('OCT_LINK')}</Link>
          </Grid>
        }
      </Grid>
    </Container>
  );
}
import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../components/loadingButton';
import utils from '../services/utils.service';
import { useApi } from '../context';

const emailRegexp = new RegExp('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,6})+$');

export const UpdateEmail = ({ email, onClose, open }: { email?: string, onClose: () => void, open: boolean }) => {
  const { t } = useTranslation('account');
  const api = useApi();

  const [updatedEmail, setUpdatedEmail] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);

  React.useEffect(() => {
    if (email) {
      setUpdatedEmail(email);
    }
    setEmailError(false);
  }, [email]);

  const submit = () => {
    if (!emailRegexp.test(updatedEmail)) {
      setEmailError(true);
      return;
    }
    utils.runAsync(async () => {
      setLoading(true);
      await api.updateEmail(updatedEmail);
      onClose();
    }, (e) => {
      if(e){
        console.error(e);
      }
      setLoading(false);
    })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog'
    >
      <DialogTitle id='dialog-title'>{t('UPDATE_EMAIL_TITLE')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              {t('UPDATE_EMAIL_INSTRUCTIONS')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('EMAIL')}
              variant='outlined'
              fullWidth={true}
              value={updatedEmail}
              onChange={(e) => {
                setEmailError(false);
                setUpdatedEmail(e.target.value)
              }}
              error={emailError}
              helperText={emailError?t('WRONG_EMAIL_FORMAT'):''}
              type='text'
              inputProps={{
                autoComplete: 'chrome-off',
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <LoadingButton loading={loading} disabled={loading} onClick={submit} color='primary' variant='outlined' autoFocus>
          {t('UPDATE')}
        </LoadingButton>
      </DialogActions>
    </Dialog >
  )
}
import React from 'react';
import { createStyles, Grid, IconButton, InputAdornment, makeStyles, Paper, TextField, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../components/loadingButton';
import { useApi } from '../context';
import utils from '../services/utils.service';
import { AlertDialog } from '../components/alertDialog';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

type FormField = {
  value: string;
  errorState: boolean;
  helperText: string;
}

const pinRegexp = new RegExp('^[0-9]{4}$');

export const NewPassword = ({ username, onSuccess }: { username: string, onSuccess: () => void }) => {
  const { t } = useTranslation('recoverPassword');
  const api = useApi();

  const [form, updateForm] = React.useReducer((
    state: { [key: string]: FormField },
    action: { field: string, data: Partial<FormField> }
  ): { [key: string]: FormField } => {
    return {
      ...state,
      [action.field]: {
        ...state[action.field],
        ...action.data
      }
    };
  },
    {
      securityCode: {
        value: '',
        errorState: false,
        helperText: ''
      },
      newPassword: {
        value: '',
        errorState: false,
        helperText: ''
      },
    }
  );

  const [submitting, setSubmitting] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [showSuccess, setShowSuccess] = React.useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState({ body: '', title: '' })
  const [showPassword, setShowPassword] = React.useState(false);

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateForm({ field: e.target.name, data: { value: e.target.value, errorState: false, helperText: '' } });
  }

  const validate = (): boolean => {
    let isValid = true;
    if (form.securityCode.value.length === 0) {
      isValid = false;
      updateForm({ field: 'securityCode', data: { errorState: true, helperText: t('REQUIRED') } });
    }
    if (!pinRegexp.test(form.newPassword.value)) {
      isValid = false;
      updateForm({ field: 'newPassword', data: { errorState: true, helperText: t('PIN_FORMAT_ERROR') } });
    }
    return isValid;
  }

  const submit = () => {
    if (!validate()) {
      return;
    }
    setSubmitting(true);
    utils.runAsync(async () => {
      await api.resetPassword(username, form.securityCode.value, form.newPassword.value);
      setShowSuccess(true);
    }, (e) => {
      if (e) {
        setAlertMessage({ title: 'INVALID_SECURITY_CODE_TITLE', body: 'INVALID_SECURITY_CODE_BODY' });
        setShowAlert(true);
      }
      setSubmitting(false);
    });
  }

  return (
    <>
      <AlertDialog
        open={showAlert}
        onClose={() => setShowAlert(false)}
        title={t(alertMessage.title)}
        message={t(alertMessage.body)}
      />
      <AlertDialog
        open={showSuccess}
        onClose={()=>{onSuccess()}}
        title={t('SUCCESS_TITLE')}
        message={t('SUCCESS_BODY')}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
                {t('NEW_PASSWORD_INSTRUCTIONS',{username})}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label={t('SECURITY_CODE')}
                value={form.securityCode.value}
                error={form.securityCode.errorState}
                helperText={form.securityCode.helperText}
                onChange={handleFormChange}
                variant='outlined'
                name={'securityCode'}
                fullWidth={true}
                inputProps={{
                  autoComplete: 'chrome-off',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
            <TextField
                  label={t('PIN')}
                  variant='outlined'
                  fullWidth={true}
                  value={form.newPassword.value}
                  error={form.newPassword.errorState}
                  helperText={form.newPassword.helperText}
                  onChange={handleFormChange}
                  name={'newPassword'}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => { setShowPassword(!showPassword) }}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  inputProps={{
                    autoComplete: 'chrome-off',
                  }}
                />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justify='flex-end'>
            <Grid item>
              <LoadingButton
                variant='contained'
                color='primary'
                disabled={submitting}
                loading={submitting}
                onClick={() => { submit() }}>
                {t('SUBMIT')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )

}
import { AccountField } from './accountField.type';
import { AccountType } from './acocuntType.type';
import { Country } from './country.type';
import { Currency } from './currency.type';
import { IdCardType } from './idCardType.enum';
import { Location } from './location.type';
import { Promotion } from './promotion.type';
import { TransactionType } from './transactionType.type';

export enum TransactionStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  ISSUED='ISSUED'
}

export type Transaction = {
  id: number;
  concept: string;
  created: string;
  reference: string;
  external_reference: string;
  status: TransactionStatus;
  receive_amount: number;
  amount: number;
  send_amount: number;
  reward: number;
  reward_description?: string;
  promotion_id?:string;
  promotion_amount?:number;
  source_currency: Currency;
  product: {
    agent: {
      id: number;
      name: string;
    };
    country: Country;
    currency: Currency;
    transaction_type: TransactionType;
  };
  receiver: {
    first_name: string;
    last_name: string;
    id_card_country_id: number;
    id_card_country: Country;
    id_card_number: string;
    id_card_type: IdCardType;
    id_card_expiry_date?: string;
    id_card_issue_date?: string;
    residence_country_id?: number;
    residence_country?:Country;
    address?: string;
    city?: string;
    postal_code?: string;
    region?: string;
    phone: string;
    email: string;
    account_fields?: AccountField[];
    account_type_id?: string;
    account_type?:AccountType;
  };
  refunded_on?: string;
  failure_reason?: string;
  location?: Location;
  promotion?:Promotion;
}
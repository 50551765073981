import React from 'react';
import { Box, Button, CircularProgress, Grid, makeStyles, Paper, TextField, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import utils from '../services/utils.service';
import { useApi } from '../context';
import { Country } from '../services/types/country.type';
import { UserProfile } from '../services/types/userProfile.type';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { UpdateEmail } from './updateEmail';
import { UpdatePassword } from './updatePassword';

const useStyles = makeStyles((theme: Theme) => ({

}));

export const Profile = () => {
  const { t } = useTranslation('account');

  const api = useApi();

  const [loading, setLoading] = React.useState(true);
  const [editEmail, setEditEmail] = React.useState(false);
  const [editPassword, setEditPassword] = React.useState(false);
  const [countries, setCountries] = React.useState<{ [key: number]: Country }>({});
  const [profileData, setProfileData] = React.useState<UserProfile | undefined>(undefined);

  const loadProfile = () => {
    utils.runAsync(async () => {
      const pData = await api.getMyProfile();
      setProfileData(pData);
    });
  }

  React.useEffect(() => {
    utils.runAsync(async () => {
      const countries = await api.getCountries();
      setCountries(
        countries.reduce((p, c) => {
          p[c.id] = c;
          return p;
        }, {} as { [key: number]: Country })
      );
      const pData = await api.getMyProfile();
      setProfileData(pData);
    }, () => {
      setLoading(false);
    })
  }, []);

  return (
    <>
      <UpdateEmail
        open={editEmail}
        onClose={() => {
          setEditEmail(false);
          loadProfile();
        }}
        email={profileData?.profile.data.email} />
      <UpdatePassword
        open={editPassword}
        onClose={() => {
          setEditPassword(false);
        }}
      />
      <Paper>
        <Box padding={2}>
          <Typography variant="h5" gutterBottom>
            {t('PROFILE')}
          </Typography>
          {loading &&
            <Grid item container justify='center'>
              <Box padding={4}>
                <CircularProgress />
              </Box>
            </Grid>
          }
          {!loading &&
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='subtitle1'>
                  {t('PERSONAL_DATA')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={t('NAME')}
                  variant='outlined'
                  disabled
                  fullWidth={true}
                  value={profileData?.profile.data.first_name}
                  type='text'
                  inputProps={{
                    autoComplete: 'chrome-off',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={t('LAST_NAME')}
                  variant='outlined'
                  disabled
                  fullWidth={true}
                  value={profileData?.profile.data.last_name}
                  type='text'
                  inputProps={{
                    autoComplete: 'chrome-off',
                  }}
                />
              </Grid>
              {(profileData?.kyc.data?.id_card_country_id && profileData?.kyc.data?.id_card_expiry_date) &&
                <>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label={t('NATIONALITY')}
                      variant='outlined'
                      disabled
                      fullWidth={true}
                      value={countries[profileData.kyc.data.id_card_country_id].iso3}
                      type='text'
                      inputProps={{
                        autoComplete: 'chrome-off',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label={t('ID_CARD_TYPE')}
                      variant='outlined'
                      disabled
                      fullWidth={true}
                      value={t(profileData!.kyc.data.id_card_type!)}
                      type='text'
                      inputProps={{
                        autoComplete: 'chrome-off',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label={t('ID_EXPIRY_DATE')}
                      variant='outlined'
                      disabled
                      fullWidth={true}
                      value={profileData?.kyc.data.id_card_expiry_date}
                      type='text'
                      inputProps={{
                        autoComplete: 'chrome-off',
                      }}
                    />
                  </Grid>
                </>
              }
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Typography variant='subtitle1'>
                      {t('ADDRESS')}
                    </Typography>
                  </Grid>
                  {/* <Grid item>
                    <IconButton color='primary' size='small'>
                      <EditIcon />
                    </IconButton>
                  </Grid> */}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t('STREET')}
                  variant='outlined'
                  disabled
                  fullWidth={true}
                  value={profileData?.profile.data.address}
                  type='text'
                  inputProps={{
                    autoComplete: 'chrome-off',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={t('CITY')}
                  variant='outlined'
                  disabled
                  fullWidth={true}
                  value={profileData?.profile.data.city}
                  type='text'
                  inputProps={{
                    autoComplete: 'chrome-off',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={t('POSTAL_CODE')}
                  variant='outlined'
                  disabled
                  fullWidth={true}
                  value={profileData?.profile.data.postal_code}
                  type='text'
                  inputProps={{
                    autoComplete: 'chrome-off',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={t('REGION')}
                  variant='outlined'
                  disabled
                  fullWidth={true}
                  value={profileData?.profile.data.region}
                  type='text'
                  inputProps={{
                    autoComplete: 'chrome-off',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={t('COUNTRY')}
                  variant='outlined'
                  disabled
                  fullWidth={true}
                  value={profileData?.profile.data.residence_country_id ? countries[profileData?.profile.data.residence_country_id].iso3 : ''}
                  type='text'
                  inputProps={{
                    autoComplete: 'chrome-off',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Typography variant='subtitle1'>
                      {t('CONTACT_DATA')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton color='primary' size='small' onClick={() => setEditEmail(true)}>
                      <EditIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t('PHONE_NUMBER')}
                  variant='outlined'
                  disabled
                  fullWidth={true}
                  value={profileData?.profile.data.phone}
                  type='text'
                  inputProps={{
                    autoComplete: 'chrome-off',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t('EMAIL')}
                  variant='outlined'
                  disabled
                  fullWidth={true}
                  value={profileData?.profile.data.email}
                  type='text'
                  inputProps={{
                    autoComplete: 'chrome-off',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='subtitle1'>
                  {t('SECURITY')}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: 25 }}>
                <Button variant='contained' color='primary' onClick={()=>setEditPassword(true)}>
                  {t('UPDATE_PASSWORD')}
                </Button>
              </Grid>
            </Grid>
          }
        </Box>
      </Paper>
    </>
  );
}
import React, { Reducer, Dispatch } from 'react';
import { Api } from './services/api.service';
import { Config } from './config';
import { Currency } from './services/types/currency.type';
import { TransactionType } from './services/types/transactionType.type';
import { Country } from './services/types/country.type';
import { Agent } from './services/types/agent.type';
import { Account } from './services/types/account.type';
import { IdCardType } from './services/types/idCardType.enum';
import { Location } from './services/types/location.type';
import { Auth } from './services/auth.service';
import { BeneficiaryType } from './transfer/beneficiary';
import { Quotation } from './services/types/quotation.type';

const config = new Config();
type Data = {
  auth: {
    ready: boolean;
    isSignedIn: boolean;
    tempCredentials?: {
      username: string;
      password: string;
    }
  },
  productSelectionParams?: {
    country: Country,
    currency: Currency,
    sourceCurrency: Currency,
    transactionType: TransactionType,
    exchangeRate: number,
    amount: number,
    mode:'SEND'|'RECEIVE';
  },
  quotation?:Quotation;
  productAttributes?: {
    agent?: Agent,
    location?: Location,
    account?: Account
  },
  beneficiary?: {
    name: string;
    lastName: string;
    idCardIssueCountry: Country;
    idCardType: IdCardType;
    idCardNumber: string;
    idCardIssueDate?: string;
    idCardExpiryDate?: string;
    countryOfResidence: Country;
    region: string;
    address: string;
    postalCode?: string;
    city: string;
    phone: string;
    email: string;
    locale?: string;
  },
  beneficiaryType?: BeneficiaryType,
  beneficiaryContactId?: number,
  concept?: string;
  revolupayOrderId?: number;
  revolupayOrderReference?: string;
};

export type Context = {
  data: Data;
  setData: Dispatch<Partial<Data>>,
  reset: () => void
};

const RevolusendContext = React.createContext<Context | undefined>(undefined);

export function useApi(): Api {
  const context = React.useContext(RevolusendContext);
  if (context === undefined) {
    throw new Error('Use api must be used inside of RevolusendProvider');
  }
  return new Api(config, new Auth(config, context));
}

export function useAuth(): Auth {
  const context = React.useContext(RevolusendContext);
  if (context === undefined) {
    throw new Error('Use api must be used inside of RevolusendProvider');
  }
  return new Auth(config, context);
}

export function useContext(): Context {
  const context = React.useContext(RevolusendContext);
  if (context === undefined) {
    throw new Error('Use Store must be used inside of RevolusendProvider');
  }
  return context;
}

export const RevolusendProvider = ({ children }: { children: any }) => {
  const reducer: Reducer<Data, Partial<Data>> = (state: Data, action: Partial<Data>) => {
    return {
      ...state,
      ...action
    };
  }

  const [data, setData] = React.useReducer(
    reducer,
    {
      auth: {
        ready: false,
        isSignedIn: false,
      }
    }
  );

  const reset = () => {
    setData({
      auth: {
        ready: data.auth.ready,
        isSignedIn: data.auth.isSignedIn,
        tempCredentials: undefined,
      },
      productSelectionParams: undefined,
      productAttributes: undefined,
      beneficiary: undefined,
      beneficiaryType: undefined,
      beneficiaryContactId: undefined,
      concept: undefined,
      revolupayOrderId: undefined,
      revolupayOrderReference: undefined
    });
  }

  return (
    <RevolusendContext.Provider value={{ data, setData, reset }}>
      {children}
    </RevolusendContext.Provider>
  )
}

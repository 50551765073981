import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route as DomRoute,
  useLocation
} from 'react-router-dom';
import { Widget } from './widget/widget';
import { PaymentKo } from './payment/paymentKo';
import { PaymentOk } from './payment/paymentOk';
import { PaymentCheck } from './payment/paymentCheck';
import { Layout } from './layout/layout';
import { Account } from './account/account';
import { Checkout } from './transfer/checkout';
import { Destination } from './transfer/destination';
import { Beneficiary } from './transfer/beneficiary';
import { Options } from './transfer/options';
import { useAuth } from './context';
import { Signup } from './signup/signup';
import { LocationsView } from './locations/locationsView';
import ReactGA from 'react-ga';
import { Config } from './config';
import { Verify } from './signup/verify';
import { Profile } from './signup/profile';
import { Kyc } from './signup/kyc';
import { RecoverPassword } from './recoverPassword/recoverPassword';
import { OctKo } from './payment/octKo';
import { OctOk } from './payment/octOk';

const config = new Config();
if (config.GOOGLE_ANALITYCS_ID) {
  ReactGA.initialize(config.GOOGLE_ANALITYCS_ID);
}

const Route = ({ component: Component, ...rest }: { component: any, exact?: boolean, path: string[] | string }) => (
  <DomRoute {...rest} render={(props) => <Layout><Component {...props} /></Layout>} />
)

const WidgetRoute = ({ component: Component, ...rest }: { component: any, exact?: boolean, path: string[] | string }) => (
  <DomRoute {...rest} render={(props) => <Component {...props} />} />
)

const ScrollToTop = () => {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (config.GOOGLE_ANALITYCS_ID) {
      ReactGA.pageview(pathname);
    }
  }, [pathname]);
  return null;
}

export const Router = () => {
  const auth = useAuth();
  React.useEffect(() => {
    auth.attemptNonInteractiveLogin();
  }, []);
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route exact path={['/transfer/destination', '/transfer']} component={Destination} />
        <Route exact path='/transfer/options' component={Options} />
        <Route exact path='/transfer/beneficiary' component={Beneficiary} />
        <Route exact path='/transfer/checkout' component={Checkout} />
        <Route exact path='/payments/check' component={PaymentCheck} />
        <Route exact path='/payments/ok' component={PaymentOk} />
        <Route exact path='/payments/ok/:transactionReference' component={PaymentOk} />
        <Route exact path='/payments/ko/:transactionId' component={PaymentKo} />
        <Route exact path='/payments/ko' component={PaymentKo} />
        <Route exact path='/account' component={Account} />
        <Route exact path='/cuenta' component={Account} />
        <Route exact path='/locations' component={LocationsView} />
        <WidgetRoute path='/widget' component={Widget} />
        <Route exact path='/signup' component={Signup} />
        <Route exact path='/registro' component={Signup} />
        <Route exact path='/signup/verify' component={Verify} />
        <Route exact path='/signup/profile' component={Profile} />
        <Route exact path='/signup/kyc' component={Kyc} />
        <Route exact path='/recover-password' component={RecoverPassword} />
        <Route exact path='/oct/:transactionReference/ko' component={OctKo}/>
        <Route exact path='/oct/:transactionReference/ok' component={OctOk}/>
        <Route path='/' component={Destination} />
      </Switch>
    </BrowserRouter>
  );
}

import React from 'react';
import { Grid, Typography, CircularProgress, TextField, makeStyles, Button, Avatar } from '@material-ui/core';
import { SignupWizard, SingupWizardStep } from './wizard';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import { useApi, useContext } from '../context';
import utils from '../services/utils.service';
import { Country } from '../services/types/country.type';
import { Alert, Autocomplete } from '@material-ui/lab';
import { DatePicker } from '@material-ui/pickers';
import FaceIcon from '@material-ui/icons/Face';
import { AlertDialog } from '../components/alertDialog';
import { LoadingButton } from '../components/loadingButton';
import { Moment } from 'moment';
import { Region } from '../services/types/region.type';
import { UserProfile } from '../services/types/userProfile.type';
import { isDesktop } from 'react-device-detect';
import { WebcamDialog } from '../components/webcamDialog';

const useStyles = makeStyles(theme => ({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  avatar: {
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  avatarIcon: {
    fontSize: 82
  }
}));

type FormField = {
  value: string;
  errorState: boolean;
  helperText: string;
}

export const Profile = () => {
  const { t, i18n } = useTranslation(['signup', 'countries']);
  const history = useHistory();
  const api = useApi();
  const context = useContext();


  const [form, updateForm] = React.useReducer((
    state: { [key: string]: FormField },
    action: { field: string, data: Partial<FormField> }
  ): { [key: string]: FormField } => {
    return {
      ...state,
      [action.field]: {
        ...state[action.field],
        ...action.data
      }
    };
  },
    {
      birthDate: {
        value: '',
        errorState: false,
        helperText: ''
      },
      country: {
        value: '',
        errorState: false,
        helperText: ''
      },
      region: {
        value: '',
        errorState: false,
        helperText: ''
      },
      street: {
        value: '',
        errorState: false,
        helperText: ''
      },
      postalCode: {
        value: '',
        errorState: false,
        helperText: ''
      },
      city: {
        value: '',
        errorState: false,
        helperText: ''
      },
      securityQuestion: {
        value: '',
        errorState: false,
        helperText: ''
      }
    }
  );

  const [loadingCountries, setLoadingCountries] = React.useState(true);
  const [loadingProfile, setLoadingProfile] = React.useState(true);
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [selectedCountry, setSelectedCountry] = React.useState<Country | null>(null);
  const [updatingProfile, setUpdatingProfile] = React.useState(false);
  const [avatarFile, setAvatarFile] = React.useState<File | undefined>(undefined);
  const [avatarCapture, setAvatarCapture] = React.useState<string | undefined>(undefined);
  const [avatarFileId, setAvatarFileId] = React.useState<string | null>(null);
  const [uploadingAvatar, setUploadingAvatar] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [birthDate, setBirthDate] = React.useState<Moment | null>(null);
  const [regions, setRegions] = React.useState<Region[]>([]);
  const [selectedRegion, selectRegion] = React.useState<Region | null>(null);
  const [loadingRegions, setLoadingRegions] = React.useState(false);
  const [profile, setProfile] = React.useState<UserProfile | null>(null);
  const [captureWebcam, setCaptureWebcam] = React.useState(false);

  React.useEffect(() => {
    utils.runAsync(async () => {
      setCountries(await api.getCountries());
    }, (countriesError) => {
      setLoadingCountries(false);
    });
  }, []);

  React.useEffect(() => {
    if (context.data.auth.isSignedIn) {
      loadProfile();
    }
  }, [context.data.auth.isSignedIn]);

  React.useEffect(() => {
    if (profile?.profile.complete) {
      history.push('/signup/kyc');
    }
  }, [profile]);

  const loadProfile = () => {
    utils.runAsync(async () => {
      setLoadingProfile(true);
      setProfile(await api.getMyProfile());
    }, (e) => {
      setLoadingProfile(false);
    });
  }

  const validate = () => {
    let isValid = true;
    if (!avatarFileId) {
      isValid = false;
    }
    if (!birthDate) {
      isValid = false;
      updateForm({ field: 'birthDate', data: { errorState: true, helperText: t('REQUIRED') } });
    }
    if (!selectedCountry) {
      isValid = false;
      updateForm({ field: 'country', data: { errorState: true, helperText: t('REQUIRED') } });
    }
    if (!selectedRegion && form.region.value.length === 0) {
      isValid = false;
      updateForm({ field: 'region', data: { errorState: true, helperText: t('REQUIRED') } });
    }
    if (form.street.value.length === 0) {
      isValid = false;
      updateForm({ field: 'street', data: { errorState: true, helperText: t('REQUIRED') } });
    }
    if (form.postalCode.value.length === 0) {
      isValid = false;
      updateForm({ field: 'postalCode', data: { errorState: true, helperText: t('REQUIRED') } });
    }
    if (form.city.value.length === 0) {
      isValid = false;
      updateForm({ field: 'city', data: { errorState: true, helperText: t('REQUIRED') } });
    }
    return isValid;
  }

  const submit = () => {
    if (!validate()) {
      return;
    }
    utils.runAsync(async () => {
      setUpdatingProfile(true);
      await api.updateProfile({
        avatar_file_id: avatarFileId!,
        address: form.street.value,
        city: form.city.value,
        region: form.region.value ? form.region.value : selectedRegion!.code,
        postal_code: form.postalCode.value,
        country_id: selectedCountry!.id,
        birth_date: birthDate!.format('YYYY-MM-DD')
      })
      history.push('/signup/kyc')
    }, (e) => {
      if (e) {
        setError('COULD_NOT_UPDATE_PROFILE');
      }
      setUpdatingProfile(false);
    })
  }

  const onAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length && event.target.files.length > 0) {
      const file = event.target.files[0]
      if (file.size > 5000000) {
        setError('MAX_FILE_SIZE_EXCEEDED');
        return;
      }
      utils.runAsync(async () => {
        setUploadingAvatar(true);
        const { signedUrl, fileId } = await api.getUploadUrl(file.type)
        await api.uploadFile(file, signedUrl);
        setAvatarFile(file);
        setAvatarFileId(fileId);
      }, (e) => {
        if (e) {
          setError('COULD_NOT_UPLOAD_FILE');
        }
        setUploadingAvatar(false);
      });
    } else {
      setAvatarFile(undefined);
    }
  }

  const onCaptureWebcam = (picture: string) => {
    utils.runAsync(async () => {
      setUploadingAvatar(true);
      setAvatarCapture(picture);
      const { signedUrl, fileId } = await api.getUploadUrl('image/jpeg');
      await api.uploadFile(utils.dataURItoBlob(picture), signedUrl);
      setAvatarFileId(fileId);
    }, (e) => {
      if (e) {
        setError('COULD_NOT_UPLOAD_FILE');
        setAvatarCapture(undefined);
      }
      setUploadingAvatar(false);
    });
  }

  const loadRegions = (id: number) => {
    utils.runAsync(async () => {
      setLoadingRegions(true);
      updateForm({ field: 'region', data: { errorState: false, helperText: '', value: '' } });
      setRegions(await api.getRegions(id));
    }, () => {
      setLoadingRegions(false);
    });
  }


  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateForm({ field: e.target.name, data: { value: e.target.value, errorState: false, helperText: '' } });
  }

  const classes = useStyles();

  if (!context.data.auth.ready) {
    return (
      <Grid container justify='center'>
        <CircularProgress />
      </Grid>
    )
  } else {
    if (!context.data.auth.isSignedIn) {
      return <Redirect to='/account' />
    }
  }

  return (
    <SignupWizard
      canGoNext={avatarFileId != null}
      loading={updatingProfile}
      next={submit}
      step={SingupWizardStep.COMPLETE_PROFILE}
    >
      <React.Fragment>
        <WebcamDialog
          open={captureWebcam}
          onPicture={(picture) => {
            setCaptureWebcam(false);
            onCaptureWebcam(picture);
          }}
          onClose={() => {
            setCaptureWebcam(false)
          }}
        />
        <AlertDialog
          onClose={() => setError(undefined)}
          open={error !== undefined}
          title={t('GENERIC_ERROR_TITLE')}
          message={t(error ? error : 'GENERIC_ERROR_TITLE')}
        />
        <Grid container spacing={2}>
          {!loadingCountries && !loadingProfile &&
            <React.Fragment>
              {profile?.kyc.status === 'REJECTED' &&
                <Grid item xs={12}>
                  <Alert severity='warning'>
                    {t('KYC_PROFILE_REJECTED_INSTRUCTIONS')}
                  </Alert>
                </Grid>
              }
              <Grid item xs={12}>
                <Typography>
                  {t('COMPLETE_PROFILE_INSTRUCTIONS')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>
                  {t('PROFILE')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems='center' direction='column' spacing={1}>

                  {!isDesktop &&
                    <>
                      <Grid item>
                        <Avatar src={avatarFile ? URL.createObjectURL(avatarFile) : undefined} className={classes.avatar}>
                          <FaceIcon className={classes.avatarIcon} color='secondary' />
                        </Avatar>
                      </Grid>
                      <Grid item>
                        <LoadingButton
                          loading={uploadingAvatar}
                          disabled={uploadingAvatar}
                          component='label'
                          color='secondary'>
                          {t('UPLOAD_AVATAR')}
                          <input
                            type='file'
                            accept='image/jpeg'
                            capture='user'
                            onChange={onAvatar}
                            hidden />
                        </LoadingButton>
                      </Grid>
                    </>
                  }
                  {isDesktop &&
                    <>
                      <Grid item>
                        <Avatar src={avatarCapture ? avatarCapture : undefined} className={classes.avatar}>
                          <FaceIcon className={classes.avatarIcon} color='secondary' />
                        </Avatar>
                      </Grid>
                      <Grid item>
                        <LoadingButton
                          loading={uploadingAvatar}
                          disabled={uploadingAvatar}
                          onClick={() => {
                            setCaptureWebcam(true);
                          }}
                          color='secondary'>
                          {t('UPLOAD_AVATAR')}
                        </LoadingButton>
                      </Grid>
                    </>
                  }
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  clearable
                  okLabel={t('OK')}
                  cancelLabel={t('CANCEL')}
                  clearLabel={t('CLEAR')}
                  fullWidth={true}
                  openTo='year'
                  label={t('BIRTHDATE')}
                  format='DD/MM/YYYY'
                  disableFuture
                  inputVariant='outlined'
                  value={birthDate}
                  error={form.birthDate.errorState}
                  helperText={form.birthDate.helperText}
                  onChange={(value) => {
                    setBirthDate(value);
                    updateForm({ field: 'birthDate', data: { errorState: false, helperText: '' } });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>
                  {t('ADDRESS')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  onChange={(event, value) => {
                    updateForm({ field: 'country', data: { errorState: false, helperText: '' } });
                    setSelectedCountry(value);
                    selectRegion(null);
                    if (value) {
                      loadRegions(value.id);
                    }
                  }}
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={selectedCountry}
                  options={countries}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => t('countries:' + option.iso3)}
                  renderOption={(option) => t('countries:' + option.iso3)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('COUNTRY_OF_RESIDENCE')}
                      variant='outlined'
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'chrome-off',
                      }}
                      error={form.country.errorState}
                      helperText={form.country.helperText}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {!loadingRegions &&
                  <React.Fragment>
                    {regions.length === 0 &&
                      <TextField
                        fullWidth={true}
                        label={t('STATE_OR_PROVINCE')}
                        variant='outlined'
                        name={'region'}
                        value={form.region.value}
                        error={form.region.errorState}
                        helperText={form.region.helperText}
                        onChange={handleFormChange}
                      />
                    }
                    {regions.length > 0 &&
                      <Autocomplete
                        disabled={loadingRegions}
                        value={selectedRegion}
                        onChange={(event, value) => {
                          updateForm({ field: 'region', data: { errorState: false, helperText: '' } });
                          selectRegion(value);
                        }}
                        getOptionSelected={(option, value) => option.code === value.code}
                        options={regions}
                        classes={{
                          option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('STATE_OR_PROVINCE')}
                            variant='outlined'
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'chrome-off',
                            }}
                            error={form.region.errorState}
                            helperText={form.region.helperText}
                          />
                        )}
                      />
                    }
                  </React.Fragment>
                }
                {loadingRegions &&
                  <Grid container justify='center' alignItems='center' style={{ height: '100%' }}>
                    <CircularProgress size={35} />
                  </Grid>
                }
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth={true}
                  label={t('STREET')}
                  variant='outlined'
                  name={'street'}
                  value={form.street.value}
                  error={form.street.errorState}
                  helperText={form.street.helperText}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth={true}
                  label={t('POSTAL_CODE')}
                  variant='outlined'
                  name={'postalCode'}
                  value={form.postalCode.value}
                  error={form.postalCode.errorState}
                  helperText={form.postalCode.helperText}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth={true}
                  label={t('CITY')}
                  variant='outlined'
                  name={'city'}
                  value={form.city.value}
                  error={form.city.errorState}
                  helperText={form.city.helperText}
                  onChange={handleFormChange}
                />
              </Grid>
            </React.Fragment>
          }
          {(loadingCountries || loadingProfile) &&
            <Grid item xs={12}>
              <Grid container justify='center'>
                <CircularProgress />
              </Grid>
            </Grid>
          }
        </Grid>
      </React.Fragment>
    </SignupWizard>
  );
}


